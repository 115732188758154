import React from "react"
import { Modal } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import "./ModalBlocked.scss"

export default function ModalPopup({ status, content }) {
  if (!content) {
    return <></>
  }

  return (
    <Modal isOpen={status} toggle={false} centered className="modal-blocked">
      <div className="modal-blocked--content">
        {/*<FontAwesomeIcon icon={"ban"} />*/}
        <FontAwesomeIcon icon="fa-solid fa-rotate-right" />
        <strong className="modal-blocked--content--red">Olá!</strong>
        {!content.getWebsiteVersionFailed ? (
          <span>
            Notamos que você está acessando uma versão desatualizada do nosso site{" "}
            {!!content.buildVersion ? <strong>({content.buildVersion})</strong> : ""}{" "}
          </span>
        ) : (
          ""
        )}
        <span>
          Por favor, atualize a página para carregar a versão mais recente{" "}
          {!!content.currentVersion ? <strong>({content.currentVersion})</strong> : ""}
        </span>
        <strong className="modal-blocked--content--red">Obrigado!</strong>
      </div>
    </Modal>
  )
}
