import React, { useState, useRef, useEffect } from "react"

import { Map, Marker } from "react-map-gl"
import mapboxgl from "mapbox-gl"
import homeMarker from "../assets/img/general/homemarker.png"
import deliverymanMarker from "../assets/img/general/helmet.png"
import markerIcon from "../assets/img/general/citymarker.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Styles
import "./MapsCF.scss"
import { getAddressSuggestionsByName } from "../helpers/AddressessTools"
import CompanyContext from "../contexts/CompanyContext"

const MAPBOX_KEY = process.env.REACT_APP_MAPBOX_KEY

const MapsCF = ({
  initialViewState,
  mapLocation = null,
  deliverymanLocation = null,
  companyLocation = null,
  homeLocation = null,
  onMoveMap,
  mapHandlers = true,
}) => {
  const [viewState, setViewState] = useState(initialViewState)
  const mapRef = useRef(null)

  useEffect(() => {
    setViewState((prevState) => ({
      ...prevState,
      ...initialViewState,
    }))
  }, [initialViewState])

  useEffect(() => {
    if (mapLocation) {
      setViewState((prevState) => ({
        ...prevState,
        longitude: mapLocation.longitude,
        latitude: mapLocation.latitude,
      }))
    }
  }, [mapLocation])

  const handleZoomIn = () => {
    setViewState((prevState) => ({
      ...prevState,
      zoom: Math.min(prevState.zoom + 0.3, 20),
    }))
  }

  const handleZoomOut = () => {
    setViewState((prevState) => ({
      ...prevState,
      zoom: Math.max(prevState.zoom - 0.3, 1),
    }))
  }

  return (
    <Map
      mapLib={mapboxgl}
      mapboxAccessToken={MAPBOX_KEY}
      {...viewState}
      dragRotate={mapHandlers}
      scrollZoom={mapHandlers}
      boxZoom={mapHandlers}
      interactive={mapHandlers}
      style={{ width: "100vw", height: "100vh" }}
      mapStyle="mapbox://styles/cityfoods/clkipsu4l00q401qkccrz6u2j"
      onMove={onMoveMap}
      padding={!!homeLocation ? { bottom: 340 } : {}}
      ref={mapRef}
    >
      {!!mapHandlers ? (
        <div id="zoom-buttons">
          <button id="zoom-in" onClick={handleZoomIn}>
            +
          </button>
          <button id="zoom-out" onClick={handleZoomOut}>
            -
          </button>
        </div>
      ) : (
        ""
      )}
      {/* Pino do centro do mapa */}
      {!!mapLocation ? (
        <Marker latitude={mapLocation.latitude} longitude={mapLocation.longitude} draggable={false}>
          <div className="modal-map-address--body-marker-icon">
            <img alt="Pino central" src={markerIcon} />
          </div>
        </Marker>
      ) : (
        ""
      )}

      {/* Entregador */}
      {!!deliverymanLocation ? (
        <Marker latitude={deliverymanLocation?.latitude || 0} longitude={deliverymanLocation?.longitude || 0} anchor="center">
          <div className="order-progress--body--bottom-home-marker">
            <img alt="Entregador" src={deliverymanMarker} />
          </div>
        </Marker>
      ) : (
        ""
      )}

      {/* Da casa do cliente */}
      {!!homeLocation ? (
        <Marker latitude={homeLocation?.latitude || 0} longitude={homeLocation?.longitude || 0} anchor="center">
          <div className="order-progress--body--bottom-home-marker">
            <img alt="Casa" src={homeMarker} />
          </div>
        </Marker>
      ) : (
        ""
      )}

      {/* Pino da loja */}
      {!!companyLocation ? (
        <Marker latitude={companyLocation?.latitude || 0} longitude={companyLocation?.longitude || 0} anchor="center">
          <div className="order-progress--body--bottom-home-marker">
            <img alt="Loja" src={homeMarker} />
          </div>
        </Marker>
      ) : (
        ""
      )}
    </Map>
  )
}

const PlaceMap = ({ onPlaceSelected, showMap }) => {
  const { company } = React.useContext(CompanyContext)
  const [placemapText, setPlacemapText] = React.useState("")
  const [onFocus, setOnFocus] = React.useState(false)
  const [fetchingSuggestions, setFetchingSuggestions] = React.useState(false)
  const [suggestions, setSuggestions] = React.useState(false)
  const changingTimeoutRef = React.useRef(() => {})

  const onChangePlaceMap = (placemap) => {
    setFetchingSuggestions(true)
    setPlacemapText(placemap)
    clearTimeout(changingTimeoutRef.current)
    if (placemap.trim().length > 0) {
      changingTimeoutRef.current = setTimeout(() => {
        onGetAddressSuggestions(placemap)
      }, 1000)
    } else {
      setFetchingSuggestions(false)
    }
  }

  const onGetAddressSuggestions = async (placemap) => {
    try {
      const fetchedSuggestions = await getAddressSuggestionsByName(placemap, company?.company_address[0]?.geo_coordinates)
      if (!!fetchedSuggestions) {
        setSuggestions(fetchedSuggestions)
      }
    } catch (error) {
      console.error("Falha ao consultar sugestões", error)
    }
    setFetchingSuggestions(false)
  }

  const onSelectedSuggestion = (suggestionSelected) => {
    onPlaceSelected(suggestionSelected)
  }

  return (
    <>
      <div className={"mapscf--placemap " + (onFocus ? " selected-input" : "")}>
        <FontAwesomeIcon className="placemap-place" color="#656565" icon="search" />

        <input
          className="mapscf--placemap--input"
          placeholder="Insira o endereço ou CEP"
          onFocus={() => setOnFocus(true)}
          onBlur={() => setOnFocus(false)}
          onChange={(event) => onChangePlaceMap(event.target.value)}
          value={placemapText}
        />

        {fetchingSuggestions ? <FontAwesomeIcon className="placemap-loading" color="#656565" icon="spinner" spin /> : ""}
      </div>
      {placemapText.trim().length > 0 ? (
        <div className="mapscf--suggestions">
          {suggestions.length > 0 &&
            suggestions.map((addressSuggested, index) => {
              if (!addressSuggested.street) return ""
              return (
                <div
                  key={addressSuggested.mapbox_id}
                  className="mapscf--suggestions--item"
                  style={{
                    borderBottomColor: "#666666",
                    borderBottomWidth: 1,
                    borderBottomStyle: "dashed",
                  }}
                  onClick={() => onSelectedSuggestion(addressSuggested)}
                >
                  <div className="mapscf--suggestions--item--left">
                    <FontAwesomeIcon icon={"map-marker-alt"} />
                  </div>
                  <div className="mapscf--suggestions--item--right">
                    <div className="mapscf--suggestions--item--right--upper">
                      <span>{addressSuggested.street}</span>
                      {!!addressSuggested.number ? <span>, {addressSuggested.number}</span> : ""}
                    </div>
                    <div className="mapscf--suggestions--item--right--bottom">
                      <span style={!!addressSuggested.city ? { marginRight: 3 } : {}}>{addressSuggested.neighbourhood}</span>
                      {addressSuggested.neighbourhood && addressSuggested.city ? "," : ""}
                      <span style={!!addressSuggested.neighbourhood ? { marginLeft: 3 } : {}}>{addressSuggested.city}</span>
                    </div>
                  </div>
                </div>
              )
            })}
          <div className="mapscf--suggestions--item" onClick={showMap}>
            <div className="mapscf--suggestions--item--left">
              <FontAwesomeIcon className="icon-marker" icon="map-marker-alt" />
            </div>
            <div className="mapscf--suggestions--item--right">
              <div className="mapscf--suggestions--item--right--upper">
                <span>Não encontrei meu endereço</span>
              </div>
              <div className="mapscf--suggestions--item--right--bottom">
                <span>Buscar no mapa</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  )
}

export { MapsCF, PlaceMap }
