import { getLocalItem } from "./StorageTools"
import moment from "moment/moment"
import { formatMoney } from "./GeneralTools"
import FidelityController from "../controllers/FidelityController"

export const getLocalUser = () => {
  return getLocalItem("user", true)
}

export const userIsConnected = () => {
  const user = getLocalItem("user", true)
  return !!user
}

export const getFidelityProgress = (fidelity, user) => {
  // console.log("getFidelityProgress start", { fidelity, user })
  let actual = 0
  let total = 0
  let progress = ""
  let readyToFinish = false
  const { fidelities = [] } = user

  if (!!user && fidelities.length > 0) {
    switch (fidelity.qty_type) {
      case "value": {
        const reduceFidelitiesValue = fidelities.reduce((prev, current) => {
          const compareDate = moment().subtract(fidelity.expire_time, "days")
          const pointDate = moment(current.dt_created_at)
          if (pointDate.isBefore(compareDate) && fidelity.expire_time > 0) {
            fidelityPointExpired(current)
          } else {
            if (current.fk_id_fidelity === fidelity.id_fidelity) {
              return prev + current.val_order
            }
          }

          return prev + 0
        }, 0)
        if (reduceFidelitiesValue >= fidelity.minimum_value) {
          readyToFinish = true
        }
        actual = formatMoney(reduceFidelitiesValue, "R$ ", false)
        total = formatMoney(fidelity.minimum_value, "R$ ", false)
        progress = `${(reduceFidelitiesValue / fidelity.minimum_value) * 100}%`
        break
      }

      case "orders": {
        for (const fidelityPoint of fidelities) {
          const compareDate = moment().subtract(fidelity.expire_time, "days")
          const pointDate = moment(fidelityPoint.dt_created_at)
          // console.log("pointDate.isBefore(compareDate)", pointDate.isBefore(compareDate))
          if (pointDate.isBefore(compareDate)) {
            fidelityPointExpired(fidelityPoint)
          } else {
            if (fidelityPoint.fk_id_fidelity === fidelity.id_fidelity) {
              actual++
            }
          }
        }
        if (actual >= fidelity.minimum_value) {
          readyToFinish = true
        }
        total = fidelity.minimum_value
        progress = `${(actual / fidelity.minimum_value) * 100}%`
        break
      }
      default: {
        break
      }
    }
  }
  // console.log("getFidelityProgress finish", {
  //   actual,
  //   total,
  //   progress,
  //   readyToFinish,
  // })
  return {
    actual,
    total,
    progress,
    readyToFinish,
  }
}

// Método para remover pontos expirados
export const fidelityPointExpired = (expiredPoint) => {
  FidelityController.deleteExpiredPoint(expiredPoint.id_fidelity_customer).then((responseRemoveExpired) => {
    if (responseRemoveExpired.status === 200) {
      console.info(responseRemoveExpired.message)
    }
    if (responseRemoveExpired.status === 500) {
      console.error(responseRemoveExpired.message)
    }
  })
}

// Método para pegar o saldo da conta de cashback do cliente.
export const getCashbackValue = (movements) => {
  let value = 0
  let input = 0
  let output = 0

  if (!!movements) {
    for (const movement of movements) {
      if (movement.type_movement_customer_account === "input") {
        value += movement.val_movement
        input += movement.val_movement
      }
      if (movement.type_movement_customer_account === "output") {
        value -= movement.val_movement
        output -= movement.val_movement
      }
    }
  }

  return { value, input, output }
}
