import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Controllers
import OrderController from "../../controllers/OrderController"
import ScheduleController from "../../controllers/ScheduleController"
import PaymentsController from "../../controllers/PaymentsController"

// Helpers
import { getItemsOrder, getSubTotalOrder, getStepOrder } from "../../helpers/OrderTools"
import { formatBoolean, formatMoney, getFormatDate, onMessageDialog, setNumber, sortList } from "../../helpers/GeneralTools"

// Contexts
import LoadingContext from "../../contexts/LoadingContext"
import TabContext from "../../contexts/TabContext"
import CompanyContext from "../../contexts/CompanyContext"
import SettingsContext from "../../contexts/SettingsContext"
import UserContext from "../../contexts/UserContext"
import SocketContext from "../../contexts/SocketContext"

//Components
import CFButton from "../CFButton/CFButton"

import moment from "moment"

import "./OrdersInfo.scss"
import { Label, Progress } from "reactstrap"

const OrdersInfo = ({
  status,
  customerUid,
  returnMenu,
  handleOrderInfo,
  showOrderDetails,
  setShowOrderDetails,
  showOrderProgress,
  setShowOrderProgress,
  from,
  content,
}) => {
  const { setTabSelected, setModalPixPayment, updatePendingOrdersRef } = React.useContext(TabContext)
  const { company } = React.useContext(CompanyContext)
  const { settings } = React.useContext(SettingsContext)
  const { setLoading } = React.useContext(LoadingContext)
  const { socket } = React.useContext(SocketContext)
  const { user } = React.useContext(UserContext)

  const [ordersCustomer, setOrdersCustomer] = React.useState([])
  const [pendingOrders, setPendingOrders] = React.useState([])
  const [initialPosition, setInitialPosition] = React.useState(null)
  const [updateState, setUpdateState] = React.useState(false)

  const ordersUnformattedRef = React.useRef(null)

  React.useEffect(() => {
    if (status) {
      setLoading(true)
      getOrders()
      getPendingOrders()

      if (!!from && !!content) {
        if (from === "goToProgress") {
          const step = getStepOrder(content)

          handleOrderInfo(step, content, "progress")
        }
      }

      const socketInterval = setInterval(() => {
        if (!!socket && socket?.connected) {
          // Pedido aprovado
          socket.on("approve_orders", (socketInfo) => {
            if (socketInfo.companyId === company.id_company) {
              console.info("[OrdersInfo] Recebendo rota [approve_orders] do socket", socketInfo)

              let newOrdersList = ordersUnformattedRef.current

              const orderIndex = newOrdersList.findIndex(({ id_order }) => socketInfo.orderId === id_order)

              if (orderIndex !== -1) {
                newOrdersList[orderIndex] = { ...newOrdersList[orderIndex], st_approved: true }
              }

              ordersUnformattedRef.current = newOrdersList
              setOrdersCustomer(filterOrdersByDate(newOrdersList))
            }
          })

          // Pedido atribuído a um entregador
          socket.on("forwarderman_orders_spreaded", ({ orders, userId, companyId }) => {
            console.info("[OrdersInfo] Recebendo rota [forwarderman_orders_spreaded] do socket", {
              orders,
              userId,
              companyId,
            })

            if (companyId === company.id_company) {
              const ordersIds = ordersUnformattedRef.current.map(({ id_order }) => {
                return id_order
              })

              for (const order of orders) {
                if (ordersIds.includes(order)) {
                  getOrders()
                }
              }
            }
          })

          socket.on("bakerman_order_finished", (socketInfo) => {
            console.info("[OrdersInfo] Recebendo rota [bakerman_order_finished] do socket", socketInfo)
            if (company.id_company === socketInfo.companyId) {
              getOrders()
            }
          })

          //Motoboy iniciou o pedido
          socket.on("deliveryman_order_started", (socketInfo) => {
            if (company.id_company === socketInfo.companyId) {
              console.info("RECEBENDO DADOS DO SOCKET [OrdersInfo] [deliveryman_order_started]", socketInfo)
              const ordersIds = ordersUnformattedRef.current.map(({ id_order }) => {
                return id_order
              })

              if (ordersIds.includes(socketInfo.orderId)) {
                getOrders()

                const order = ordersUnformattedRef.current.find(({ id_order }) => id_order === socketInfo.orderId)

                setShowOrderProgress({ status: true, content: { ...order, location: socketInfo.location } })
              }
            }
          })

          //Motoboy encerrou o pedido
          socket.on("deliveryman_order_finished", (socketInfo) => {
            if (company.id_company === socketInfo.companyId) {
              console.info("RECEBENDO DADOS DO SOCKET [OrdersInfo] [deliveryman_order_finished]")
              const ordersIds = ordersUnformattedRef.current.map(({ id_order }) => {
                return id_order
              })

              if (ordersIds.includes(socketInfo.orderId)) {
                getOrders()
              }
            }
          })

          //Estabelecimento encerrou o pedido
          socket.on("finish_orders", (socketInfo) => {
            if (company.id_company === socketInfo.companyId) {
              console.info("RECEBENDO DADOS DO SOCKET [OrdersInfo] [finish_orders]")
              const ordersIds = ordersUnformattedRef.current.map(({ id_order }) => {
                return id_order
              })

              if (ordersIds.includes(socketInfo.orderId)) {
                getOrders()
              }
            }
          })
        }
        // Removendo método de interlavo
        clearInterval(socketInterval)
      }, 500)
    } else {
      socket.removeListener("approve_orders")
      socket.removeListener("forwarderman_orders_spreaded")
      socket.removeListener("deliveryman_order_started")
      socket.removeListener("deliveryman_order_finished")
      socket.removeListener("bakerman_order_finished")
      socket.removeListener("finish_orders")
    }
  }, [status])

  React.useEffect(() => {
    let newOrdersList = ordersUnformattedRef.current

    if (showOrderDetails.status) {
      setShowOrderDetails({
        status: true,
        content: newOrdersList.find((order) => order.id_order === showOrderDetails.content.id_order),
      })
    }

    if (showOrderProgress.status) {
      setShowOrderProgress({
        status: true,
        content: newOrdersList.find((order) => order.id_order === showOrderProgress.content.id_order),
      })
    }
  }, [ordersCustomer])

  React.useEffect(() => {
    setUpdateState(!updateState)
  }, [updatePendingOrdersRef])

  const onRefreshOrders = () => {
    setLoading(true)
    getOrders()
    getPendingOrders()
  }

  const filterOrdersByDate = (orders) => {
    const ordersByDate = {}

    for (const order of orders) {
      const formattedDate = getFormatDate(order.dt_created_at).getDate("DD/MM/YYYY")

      if (!!ordersByDate[formattedDate]) {
        ordersByDate[formattedDate].push(order)
      } else {
        ordersByDate[formattedDate] = [order]
      }
    }

    return ordersByDate
  }

  const getLabelDate = (date) => {
    const currentDate = getFormatDate(new Date()).getDate("DD/MM/YYYY")

    if (date === currentDate) {
      return "Hoje"
    }

    if (parseInt(date.split("/")[0]) === parseInt(currentDate.split("/")[0]) - 1) {
      return "Ontem"
    }

    return date
  }

  const getOrders = () => {
    OrderController.fetchAllByCustomer(customerUid)
      .then((responseOrderCustomer) => {
        if (responseOrderCustomer.status === 200) {
          ScheduleController.fetchAllByCustomer({
            startDate: null,
            withCustomer: false,
            onlyTotal: false,
            withFinished: true,
            withOrigin: "site",
            customerUid,
          }).then((responseSchedulesOrders) => {
            if (responseSchedulesOrders.status === 200) {
              const newOrderList = [
                ...responseOrderCustomer.data.rows,
                ...responseSchedulesOrders.data.rows.map((schedule) => {
                  return {
                    dt_created_at: schedule.dt_schedule + " 00:00",
                    dt_schedule: schedule.dt_schedule,
                    schedule: true,
                    nro_order: getFormatDate(new Date("2023-1-1 " + schedule.hr_schedule_start)).getHour("hh:mm"),
                    company: {
                      nm_company: company?.nm_company,
                    },
                    val_total: schedule.val_total,
                    modality: {
                      id_modality: schedule.fk_id_modality,
                    },
                    id_street_customer: schedule.id_street_customer,
                    start_hour: schedule.hr_schedule_start,
                  }
                }),
              ]

              newOrderList.sort((a, b) => sortList(a, b, "dt_created_at", "desc"))
              setOrdersCustomer(filterOrdersByDate(newOrderList))
              ordersUnformattedRef.current = newOrderList
            } else {
              setOrdersCustomer([])
            }
          })
          //
        } else if (responseOrderCustomer.status === 204) {
          setOrdersCustomer([])
        } else {
          onMessageDialog("info")
            .fire({
              title: responseOrderCustomer.message,
              icon: "error",
              timer: 2000,
              showCancelButton: false,
              showConfirmButton: false,
            })
            .then()
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getPendingOrders = async () => {
    const request = await PaymentsController.getPendingPayments(user.uuid_customer)

    setPendingOrders(request.data)
  }

  const getPaymentInfo = async (paymentId) => {
    const request = await PaymentsController.getPaymentInfo(paymentId)

    return request.data
  }

  React.useEffect(() => {
    const interval = setInterval(() => {
      setUpdateState(!updateState)
    }, 1000)

    return () => clearInterval(interval)
  }, [updateState])

  if (status) {
    return (
      <div className={"orders-info " + (status ? "orders-info-active" : "")}>
        <div className={"orders-info--header"}>
          <FontAwesomeIcon className="orders-info--header--return" icon={"chevron-left"} onClick={returnMenu} />
          <span>Meus pedidos</span>
          <FontAwesomeIcon className="orders-info--header--refresh" icon="refresh" onClick={onRefreshOrders} />
        </div>

        <div className="orders-info--body">
          {pendingOrders.filter((payment) => {
            const dt_created_at = moment(payment.dt_created_at)
            const time = 300 // 300 segundos = 5 minutos
            const now = moment()
            const diff = now.diff(dt_created_at, "seconds")

            return diff < time
          }).length > 0 && (
            <div className="orders-info--body--pending">
              <span className="orders-info--body--pending-title">Pedidos com pagamento pendentes</span>
              <div className="orders-info--body--pending--payments">
                {pendingOrders
                  .filter((payment) => {
                    const dt_created_at = moment(payment.dt_created_at)
                    const time = 300 // 300 segundos = 5 minutos
                    const now = moment()
                    const diff = now.diff(dt_created_at, "seconds")

                    return diff < time
                  })
                  .map((payment) => {
                    const dt_created_at = moment(payment.dt_created_at)

                    const now = moment()

                    const diff = now.diff(dt_created_at, "seconds")

                    return (
                      <div key={payment.id_payment_log} className="orders-info--body--pending--payments--item">
                        <div className="orders-info--body--pending--payments--item--header">
                          <div className="orders-info--body--pending--payments--item--header--pulse" />
                          <h1>Aguardando pagamento</h1>
                        </div>
                        <div className="orders-info--body--pending--payments--item--body">
                          <div className="orders-info--body--pending--payments--item--body--progress">
                            <span>Você tem</span>
                            <h1>
                              0{moment.duration(300 - diff, "seconds")._data.minutes}:
                              {moment.duration(300 - diff, "seconds")._data.seconds <= 9
                                ? "0" + moment.duration(300 - diff, "seconds")._data.seconds
                                : moment.duration(300 - diff, "seconds")._data.seconds}
                              <span> minutos para pagar</span>
                            </h1>
                            <Progress min={0} max={300} value={300 - diff} animated barStyle={{ backgroundColor: "#df2833" }} />
                            <div className="orders-info--body--pending--payments--item--body--total">
                              <h1>
                                <span>Total: </span>
                                {formatMoney(payment.val_payment)}
                              </h1>
                            </div>
                          </div>
                          <button
                            onClick={async () => {
                              setLoading(true)

                              await getPaymentInfo(payment.payment_id)
                                .then((response) => {
                                  setModalPixPayment({ status: true, content: response })
                                })
                                .catch((error) => {
                                  console.error(error)
                                })
                                .finally(() => {
                                  setLoading(false)
                                })
                            }}
                            className="orders-info--body--pending--payments--item--body--pay"
                          >
                            Pagar
                          </button>
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>
          )}
          {(() => {
            if (Object.keys(ordersCustomer).length > 0) {
              return Object.keys(ordersCustomer).map((orderDate, key) => {
                return (
                  <div key={key} className="orders-info--date">
                    <span>{getLabelDate(orderDate)}</span>
                    {ordersCustomer[orderDate].map((order, key) => {
                      const step = getStepOrder(order)
                      const { combos, products, beverages } = getItemsOrder(order.order_items)
                      let newCombos = []
                      for (const combo of combos) {
                        const comboIndex = newCombos.findIndex(({ id_combo }) => combo.id_combo === id_combo)
                        if (comboIndex === -1) {
                          newCombos.push(combo)
                        } else {
                          newCombos[comboIndex] = { ...newCombos[comboIndex], amount: newCombos[comboIndex].amount + combo.amount }
                        }
                      }

                      let newProducts = []
                      for (const product of products) {
                        if (!product.nro_combo && !product.id_combo) {
                          const productIndex = newProducts.findIndex(({ id_product }) => product.id_product === id_product)
                          if (productIndex === -1) {
                            newProducts.push(product)
                          } else {
                            newProducts[productIndex] = {
                              ...newProducts[productIndex],
                              amount: newProducts[productIndex].amount + product.amount,
                            }
                          }
                        }
                      }
                      let newBeverages = []
                      for (const beverage of beverages) {
                        if (!beverage.id_combo) {
                          const beverageIndex = newBeverages.findIndex(({ id_beverage }) => beverage.id_beverage === id_beverage)
                          if (beverageIndex === -1) {
                            newBeverages.push(beverage)
                          } else {
                            newBeverages[beverageIndex] = {
                              ...newBeverages[beverageIndex],
                              amount: newBeverages[beverageIndex].amount + beverage.amount,
                            }
                          }
                        }
                      }

                      return (
                        <div
                          key={key}
                          className="orders-info--item"
                          onClick={() => {
                            const thisOrder = ordersUnformattedRef.current.find(({ id_order }) => order.id_order === id_order)
                            if (!thisOrder.schedule) {
                              handleOrderInfo(step, thisOrder, "details")
                            }
                          }}
                        >
                          <div className="orders-info--item--header">
                            <div className="orders-info--item--header--top">
                              <FontAwesomeIcon style={{ color: step.color }} className="orders-info--item--circle-icon" icon="circle" />
                              <span>
                                <span>{setNumber(order.nro_order)}</span> - {order?.company?.nm_company || "Desconhecido"}
                              </span>
                              <FontAwesomeIcon className="orders-info--item--arrow-icon" icon="chevron-right" />
                            </div>
                            <div className="orders-info--item--header--bottom">
                              {(() => {
                                if (!order.st_approved && order?.order_progress?.length === 1) {
                                  return <span>Aguardando aprovação</span>
                                }
                                if (order.st_approved) {
                                  if (order.order_progress[0].master_step_id === 3 && formatBoolean(settings.dispatch_order_ready)) {
                                    return <span>Em trânsito</span>
                                  }
                                  if ([5].includes(order.order_progress[0].master_step_id)) {
                                    if (order.order_progress[0].is_final_step) {
                                      return <span>Entregue</span>
                                    }

                                    return <span>Em trânsito</span>
                                  } else {
                                    return <span>Em preparo</span>
                                  }
                                }
                              })()}
                            </div>
                          </div>
                          <div className="orders-info--item--body">
                            {newCombos.map((combo, key) => (
                              <span key={key}>
                                {setNumber(combo.amount, 2)} {combo.nm_combo}
                              </span>
                            ))}
                            {newProducts.map((product, key) => (
                              <span key={key + combos.length}>
                                {setNumber(product.amount, 2)} {product.nm_product}
                              </span>
                            ))}
                            {newBeverages.map((beverage, key) => (
                              <span key={key + combos.length + products.length}>
                                {setNumber(beverage.amount, 2)} {beverage.nm_beverage}
                              </span>
                            ))}
                          </div>
                          <div className="orders-info--item--footer readyonly-group">
                            <span>
                              <span>Total:</span> {getSubTotalOrder(order)}
                            </span>
                            {/* <Button className="readonly-group">Refazer o Pedido</Button> */}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )
              })
            } else {
              return (
                <div className="orders-info--no-items">
                  {!!settings.site_no_orders_customized_message && settings.site_no_orders_customized_message !== "" ? (
                    <>
                      <span>{settings.site_no_orders_customized_message}</span>
                      <CFButton title="Ver cardápio" onClick={() => setTabSelected("home")} />
                    </>
                  ) : (
                    <>
                      <span>Você não possui pedidos ativos ainda 😥</span>
                      <span>Mas não se preocupe, nosso cardápio está recheado de produtos que vão te dar aquela água na boca! 😋😍</span>
                      <CFButton title="Ver cardápio" onClick={() => setTabSelected("home")} />
                    </>
                  )}
                </div>
              )
            }
          })()}
        </div>
      </div>
    )
  } else {
    return ""
  }
}

export default OrdersInfo
