import React from "react"

// External libs
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment"
import "moment/locale/pt-br"

// Controllers
import FidelityController from "../../controllers/FidelityController"

// Tools
import { getFidelityProgress } from "../../helpers/UserTools"
import { getSessionItem } from "../../helpers/StorageTools"

// Contexts
import UserContext from "../../contexts/UserContext"
import SettingsContext from "../../contexts/SettingsContext"

// Modals
import ModalFidelityDetails from "../../views/modals/ModalFidelityDetails/ModalFidelityDetails"

// Styles
import "./FidelitiesInfo.scss"
import TabContext from "../../contexts/TabContext"

moment.locale("pt-br")
const FidelitiesInfo = ({ enableFidelitiesInfo, returnMenu }) => {
  const { user } = React.useContext(UserContext)
  const { fidelities, setFidelities } = React.useContext(SettingsContext)
  const { goToOrderProgress, backToOrderProgress } = React.useContext(TabContext)
  // console.log("fidelities", fidelities)
  const [showModalFidelityDetail, setShowModalFidelityDetail] = React.useState({ status: false, content: null })

  React.useEffect(() => {
    if (!fidelities || !goToOrderProgress.status) {
      FidelityController.fetchAll().then((responseFetchFidelities) => {
        if (responseFetchFidelities.status === 200) {
          setFidelities(responseFetchFidelities.data)
        }
      })
    }
  }, [])

  return (
    <div className={"fidelities-info " + enableFidelitiesInfo}>
      <div className={"fidelities-info--header"}>
        <FontAwesomeIcon
          icon={"chevron-left"}
          onClick={() => {
            returnMenu()
          }}
        />
        <span>Programas de fidelidade</span>
      </div>

      <div className={"fidelities-info--body"}>
        {fidelities.length > 0 ? (
          fidelities.map((fidelity) => {
            // console.log("================================================")
            // console.log("fidelity", fidelity)
            // console.log("user", user)
            const { actual, total, progress, readyToFinish } = getFidelityProgress(fidelity, user)
            // console.log("getFidelityProgress", { actual, total, progress, readyToFinish })

            // Se a fidelidade já foi utilizada, ela vai ser marcada como utilizada para que o cliente não possa
            // fazer diversos pedidos com o mesmo programa de fidelidade.
            let localFidelitiesUsed = getSessionItem("fidelitiesUsed", true)
            // console.log("localFidelitiesUsed", localFidelitiesUsed)
            if (!!localFidelitiesUsed) {
              const fidelityUsed = localFidelitiesUsed.find((fidelityId) => fidelityId === fidelity.id_fidelity)
              if (!!fidelityUsed) {
                return ""
              }
            }

            return (
              <div
                key={fidelity.uuid_fidelity}
                className="fidelities-info--body--item"
                onClick={() => setShowModalFidelityDetail({ status: true, content: fidelity })}
              >
                <div className="fidelities-info--body--item--top">
                  <strong>{fidelity.nm_fidelity}</strong>
                  <span>{fidelity.ds_fidelity}</span>
                </div>
                <div className="fidelities-info--body--item--bottom">
                  <div className="fidelities-info--body--item--bottom--progress-bar">
                    <div
                      className="fidelities-info--body--item--bottom--progress-bar--progress"
                      style={{ width: progress, backgroundColor: readyToFinish ? "#3bbd0d" : "#C23B35" }}
                      title={progress}
                    />
                  </div>
                  <div className="fidelities-info--body--item--bottom--value">
                    {readyToFinish ? (
                      <span style={{ color: "#3bbd0d" }}>Pronto para resgate</span>
                    ) : (
                      <span>{fidelity.qty_type === "orders" ? `${actual} / ${total} pedidos` : `${actual} / ${total}`}</span>
                    )}
                  </div>
                </div>
              </div>
            )
          })
        ) : (
          <div className="fidelities-info--body--no-fidelities">
            <span>Sem fidelidades localizadas</span>
          </div>
        )}
      </div>

      <ModalFidelityDetails
        status={showModalFidelityDetail.status}
        content={showModalFidelityDetail.content}
        onClose={() => setShowModalFidelityDetail({ status: false, content: null })}
      />
    </div>
  )
}

export default FidelitiesInfo
