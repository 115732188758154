import GeneralTools, { formatFloat, formatMoney, sortList } from "./GeneralTools"
import Message from "../components/Message"

export const extractFlavoursDetails = (product, isCombo) => {
  let { flavours, size } = product
  return flavours.map((flavour) => {
    return {
      flavour_name: `${flavour.amount > 1 ? `${flavour.amount}x ` : ""} ${flavour.nm_flavour}`,
      val_price: !!size?.st_higher_price ? "" : getPriceFlavour(isCombo, product, flavour.id_flavour, product.sizes),
      additions:
        flavour.additions?.map((addition) => {
          return getAdditionData(addition, size?.uuid_size, flavours?.length)
        }) || [],
      restrictions:
        flavour.restrictions?.map((restriction) => {
          return restriction.nm_ingredient
        }) || [],
    }
  })
}

export const extractComboFlavoursDetails = (product, isCombo) => {
  let { flavours, size } = product
  return flavours.map((flavour) => {
    return {
      flavour_name: `${flavour.amount > 1 ? `${flavour.amount}x ` : ""} ${flavour.nm_flavour}`,
      val_price: !!size.st_higher_price ? "" : getPriceFlavour(isCombo, product, flavour.id_flavour, product.size),
      additions: flavour.additions.map((addition) => {
        return getAdditionData(addition, size?.uuid_size, flavours?.length)
      }),
      restrictions: flavour.restrictions.map((restriction) => {
        return restriction.nm_ingredient
      }),
    }
  })
}

export const extractComplementsDetails = (complements) => {
  let complementData = []
  complements.forEach((complement) => {
    if (!!complement.additionals && complement.additionals.length > 0) {
      complement.additionals.forEach((additional) => {
        complementData.push({
          nm_complement: getAdditionalName(additional, complement),
          val_price: complement?.size?.complement_size?.val_price + additional?.size?.additional_size?.val_price,
        })
      })
    }
  })
  return complementData
}

export const extratComboComplementsDetails = (complements) => {
  let comboComplementData = []
  complements.forEach((complement) => {
    if (!!complement.additionals && complement.additionals.length > 0) {
      complement.additionals.forEach((additional) => {
        comboComplementData.push({
          nm_complement: getAdditionalName(additional, complement),
          val_price: complement.val_price + additional.val_price,
        })
      })
    }
  })

  return comboComplementData
}

//Retorna os dados de um adicional (ingrediente adicional)
export const getAdditionData = (addition, sizeUid, flavoursQty = 1) => {
  let additionDetails = {
    nm_ingredient: addition.nm_ingredient,
    val_price: 0,
  }

  if (!!addition.size) {
    //
    additionDetails.val_price = addition.size?.ingredient_size?.val_price || 0
  } else if (!!addition.sizes) {
    //
    const ingredientSize = addition.sizes.find(({ uuid_size }) => uuid_size === sizeUid)
    if (!!ingredientSize) {
      const { ingredient_size } = ingredientSize
      additionDetails.val_price = ingredient_size.val_price / flavoursQty
    }
  }

  return additionDetails
}

// Método para definir o valor do cupom
export const validateBenefitsValue = (order, benefit) => {
  let value = 0
  switch (benefit.tp_benefit) {
    //Desconto
    case 0: {
      if (benefit.tp_target === "CART") {
        if (benefit.tp_discount_type === 0) {
          value = (order.total * benefit.val_benefit) / 100
        } else if (benefit.tp_discount_type === 1) {
          value = benefit.val_benefit
        }
      }

      if (benefit.tp_target === "SUBTOTAL") {
        if (benefit.tp_discount_type === 0) {
          value = ((order.subTotal + order.deliveryFee) * benefit.val_benefit) / 100
        } else if (benefit.tp_discount_type === 1) {
          value = benefit.val_benefit
        }
      }

      if (benefit.tp_target === "ITEM") {
        for (const product of order.products) {
          // const productWithBenefit = order.products.find(({ ds_obs, amount }) => {
          //   return
          // })
          if (product?.ds_obs?.includes("Cupom de desconto") && product?.ds_obs?.includes(benefit.nm_benefit)) {
            const productWithBenefit = product
            let flavoursValue = productWithBenefit.flavours?.reduce((acc, flavour) => {
              let flavourPrice = getPriceFlavour(false, productWithBenefit, flavour.id_flavour, productWithBenefit.size)
              return flavourPrice + acc
            }, 0)
            // let complementsValue = productWithBenefit.complements?.reduce((acc, addition) => {
            //   let additionPrice = getPriceAddition(addition, productWithBenefit)
            //   return additionPrice + acc
            // }, 0)
            let complementsValue = getTotalComplements(productWithBenefit.complements, productWithBenefit.size, false)
            const totalProductValue = (flavoursValue + complementsValue) * productWithBenefit.amount

            if (totalProductValue > 0) {
              const { val_benefit, tp_discount_type } = benefit
              if (tp_discount_type === 0) {
                value += (totalProductValue * val_benefit) / 100
              } else if (tp_discount_type === 1) {
                value += val_benefit
              }
            } else {
              value = 0
            }
          }
        }
      }

      if (benefit.tp_target === "DELIVERY-FEE") {
        //Desconto percentual
        if (benefit.tp_discount_type === 0) {
          value = (order.deliveryFee * benefit.val_benefit) / 100
        }
        //Desconto fixo
        if (benefit.tp_discount_type === 1) {
          value = order.deliveryFee < benefit.val_benefit ? order.deliveryFee : benefit.val_benefit
        }
      }

      break
    }
    //Brinde
    case 1: {
      if (benefit.tp_gift_type === 1) {
        value = order.deliveryFee
      }
      break
    }
    default: {
      break
    }
  }
  return value < 0 ? 0 : !!value ? value : 0
}

export const getAdditionalName = (additional, complement) => {
  const { nm_additional, lb_additional } = additional
  const { nm_complement, lb_complement } = complement

  // Verificando se existe o prefixo "sem" no nome definido para o adicional (e.g. sem borda, sem molho)
  if (`${nm_additional}`.toLowerCase().indexOf("sem ") !== -1 || (lb_additional && `${lb_additional}`.toLowerCase().indexOf("sem ") !== -1)) {
    return lb_additional ? lb_additional : nm_additional
  }

  return `${lb_complement ? lb_complement : nm_complement} ${lb_additional ? lb_additional : nm_additional}`
}

export const getProductName = (product) => {
  return `${product.nm_product} ${product.size.show ? `${!!product.size.lb_size ? product.size.lb_size : product.size.nm_size}` : ""}`
}

export const getProductPrice = (product, order) => {
  const totalFlavours = getTotalFlavours(product.flavours, product.size, !!product.id_combo, product.nro_combo, product.nro_item, order)
  const totalComplements = !!product.complements
    ? getTotalComplements(product.complements, product.size, !!product.id_combo, product.nro_combo, product.nro_item)
    : 0
  const totalAdditionals = !!product.additionals
    ? getTotalAdditionals(product.additionals, product.size, !!product.id_combo, product.nro_combo, product.nro_item)
    : 0

  let totalProduct = (totalFlavours + totalComplements + totalAdditionals) * product.amount

  if (!!product.id_benefit && !!order.id_benefit_product_index) {
    for (const index of order.id_benefit_product_index) {
      if (index === product?.index) {
        const { val_benefit, tp_target, tp_discount_type } = order.benefits[0]
        if (tp_target === "ITEM") {
          if (tp_discount_type === 0) {
            totalProduct = totalProduct - (totalProduct * val_benefit) / 100
          } else if (tp_discount_type === 1) {
            totalProduct = totalProduct - val_benefit
          }
        }
      }
    }
  }

  if (!!product.id_combo) {
    return totalProduct > 0 ? formatMoney(totalProduct) : "Sem exd."
  }

  return totalProduct > 0 ? formatMoney(totalProduct) : "Grátis"
}

export const getBeverageName = (beverage) => {
  let category = "Bebida"
  if (!!beverage.category) {
    category = !!beverage.category.lb_category ? beverage.category.lb_category : beverage.category.nm_category
  }
  let name = !!beverage.lb_beverage ? beverage.lb_beverage : beverage.nm_beverage
  return [category, name]
}

//Métodos antigos ==============================
export const getPriceFlavour = (isCombo = false, product, flavourId, size = null) => {
  if (!!isCombo) {
    const { flavours, size: productSize } = product
    let flavour = flavours.find(({ id_flavour }) => id_flavour === flavourId)
    // console.log("flavour", flavour)
    if (!!flavour) {
      let value = flavour.val_price

      if (!productSize.st_higher_price && productSize.st_percentage_calc) {
        value = flavour.val_price / product.flavours.length
      }

      return GeneralTools.parseFloat(value)
    }
  } else {
    let newValue = 0

    if (size !== null) {
      if (product.size.st_percentage_calc) {
        const percentage = 100 / product.flavours.length

        const thisFlavour = product.flavours.find(({ id_flavour }) => id_flavour === flavourId)

        newValue = (thisFlavour.size.val_price * percentage) / 100
      } else {
        // newValue = product.size.val_price
        const thisFlavour = product.flavours.find(({ id_flavour }) => id_flavour === flavourId)

        if (!!thisFlavour.sizes) {
          const thisFlavourSize = thisFlavour.sizes.find(({ fk_id_flavour }) => fk_id_flavour === thisFlavour.id_flavour)

          if (!!thisFlavourSize) {
            newValue = thisFlavourSize.val_price
          }
        }

        if (!!thisFlavour.size) {
          newValue = thisFlavour.size.val_price
        }
      }
      if (newValue > 0) {
        return formatFloat(newValue)
      }
    } else {
      const productFlavour = product.flavours.find(({ id_flavour }) => id_flavour === flavourId)
      const selectedFlavourSize = !!productFlavour.sizes
        ? productFlavour.sizes.find(({ uuid_size }) => uuid_size === product.size.uuid_size)
        : productFlavour.size

      if (product.size.st_percentage_calc) {
        const percentage = 100 / product.flavours.length
        newValue = (selectedFlavourSize.val_price * percentage) / 100
      } else {
        newValue = selectedFlavourSize.val_price
      }
      if (newValue > 0) {
        return GeneralTools.parseFloat(newValue * (productFlavour.amount || 1))
      }
    }
  }

  return 0
}

export const getPriceAddition = (order, product, addition, idIngredient, nm_ingredient, amount, size, sizes) => {
  const combo = order.combos.find(({ nro_combo }) => nro_combo === product.nro_combo)
  let priceAddition = 0

  if (!!combo) {
    // const addition = additions.find(({ id_ingredient }) => id_ingredient === idIngredient)
    const priceAdd = addition.sizes.find(({ uuid_size }) => uuid_size === product.size.uuid_size)
    priceAddition = priceAdd.ingredient_size.val_price
  } else {
    if (!!addition.sizes) {
      priceAddition =
        addition.sizes.find(({ id_size }) => id_size === size.id_size).ingredient_size.val_price / (!!product ? product.flavours.length : 1)
    } else if (!!addition.size) {
      priceAddition = addition.size.ingredient_size.val_price / (!!product ? product.flavours.length : 1)
    }
  }

  return priceAddition
}

export const getTotalOrder = (order, deliveryFee = 0) => {
  let result = []
  let { combos = [], products = [], beverages = [], benefits = [] } = order

  // Total de bebidas
  if (beverages.length > 0) {
    result.push(
      beverages
        .map((beverage) => {
          if (!beverage.id_combo) {
            return GeneralTools.parseFloat(beverage.val_price || 0) * beverage.amount
          }

          const { amount } = combos.find(({ nro_combo }) => nro_combo === beverage.nro_combo)

          return GeneralTools.parseFloat(beverage.val_price * amount)
        })
        .reduce((sum, number) => {
          return sum + number
        })
    )
  }

  // Total de produtos
  if (products.length > 0) {
    result.push(
      products
        .map(
          ({
            id_combo = null,
            nro_item = null,
            nro_combo = null,
            // id_product,
            size,
            amount,
            flavours,
            additionals = null,
            complements = null,
            // beverages = null,
          }) => {
            let totalPartial = 0
            if (!id_combo) {
              totalPartial = GeneralTools.parseFloat(getTotalFlavours(flavours, size))

              if (!!complements) {
                totalPartial = totalPartial + GeneralTools.parseFloat(getTotalComplements(complements, size))
              }
              if (!!additionals) {
                totalPartial = totalPartial + GeneralTools.parseFloat(getTotalAdditionals(additionals, size))
              }
              return GeneralTools.parseFloat(totalPartial) * amount
            }

            const { amount: amountCombo } = combos.find((combo) => combo.nro_combo === nro_combo)
            totalPartial = GeneralTools.parseFloat(getTotalFlavours(flavours, size, true, nro_combo, nro_item, order))
            if (!!complements) {
              totalPartial = totalPartial + GeneralTools.parseFloat(getTotalComplements(complements, size, true, nro_combo, nro_item))
            }
            if (!!additionals) {
              totalPartial = totalPartial + GeneralTools.parseFloat(getTotalAdditionals(additionals, size, true, nro_combo, nro_item))
            }

            return GeneralTools.parseFloat(totalPartial * amountCombo)
          }
        )
        .reduce((sum, number) => {
          return sum + number
        }, 0)
    )
  }
  // Total de combos
  if (combos.length > 0) {
    for (const combo of combos) {
      result.push(GeneralTools.parseFloat(combo.val_price * combo.amount))
    }
  }
  let total = result.reduce((sum, number) => sum + number, 0)
  let subTotal = total
  // Taxa de entrega
  if (order.id_modality === 1) subTotal = subTotal + deliveryFee

  // Desconto
  let totalDiscount = 0
  if (order.discountType === "R$") {
    subTotal = subTotal - parseFloat(order.discount)
    totalDiscount = parseFloat(order.discount)
  } else if (order.discountType === "%") {
    subTotal = subTotal - subTotal * (Math.abs(parseFloat(order.discount)) / 100)
    totalDiscount = subTotal * (Math.abs(parseFloat(order.discount)) / 100)
  }
  //Total dos cupons
  let benefitsTotal = []
  for (const benefit of benefits) {
    const valBenefit = validateBenefitsValue({ ...order, deliveryFee }, benefit)
    benefitsTotal.push(valBenefit)
    //TODO: Confirmar quais rotinas do site dependem do valor do subtotal vir com o valor subtraindo o de cupons, se não precisar podemos remover essa subtração.
    subTotal = subTotal - valBenefit
  }
  let benefit = benefitsTotal.reduce((sum, number) => sum + number, 0)
  if (total < 0) total = 0
  if (subTotal < 0) subTotal = 0
  // retornando o total dos valores
  return [total, subTotal, benefit, totalDiscount]
}

export const getBenefitValue = (order, benefit) => {
  let value = 0
  if (benefit.tp_benefit === 0) {
    if (benefit.tp_discount_type === 0) {
      value = ((order.total - order.deliveryFee) * benefit.val_benefit) / 100
    } else if (benefit.tp_discount_type === 1) {
      value = benefit.val_benefit
    }
  }
  return value < 0 ? 0 : !!value ? value : 0
}

export const getTotalAdditionals = (additionals, size) => {
  let totalAdditionals = []

  if (!!additionals) {
    for (let additional of additionals) {
      // Obtendo o valor do adicional

      const additionalPrice = additional.sizes.find(({ uuid_size }) => uuid_size === size.uuid_size)

      if (!!additionalPrice) {
        totalAdditionals.push(GeneralTools.parseFloat(additionalPrice.additional_size.val_price * additional.amount))
      }
    }
  }

  return totalAdditionals.length > 0 ? totalAdditionals.reduce((sum, number) => sum + number) : 0
}

export const getTotalCombo = (combo, size) => {}

export const getTotalComplements = (complements, size, isCombo = false) => {
  let result = []
  if (isCombo) {
    if (!!size) {
      for (const complement of complements) {
        for (const additional of complement.additionals) {
          let complementValue = !!additional.val_price ? additional.val_price : 0
          if (!!additional.nm_additional) {
            if (
              `${additional.nm_additional}`.toLowerCase().indexOf("sem", 0) === -1 &&
              `${additional.nm_additional}`.toLowerCase().indexOf("normal", 0) === -1
            ) {
              complementValue = complementValue + complement.val_price
            }
          }

          result.push(complementValue)
        }
      }

      return result.length > 0
        ? result.length > 1
          ? result.reduce((sum, number) => {
              return sum + number
            })
          : result[0]
        : 0.0
    }
  } else {
    if (!!size) {
      for (const complement of complements) {
        for (const additional of complement.additionals) {
          let complementValue = 0

          const additionalSize = !!additional.sizes
            ? additional.sizes.find(({ uuid_size }) => uuid_size === size.uuid_size)
            : !!additional.size
            ? additional.size
            : null

          if (!!additionalSize) {
            complementValue = additionalSize.additional_size?.val_price * (additional?.amount || 1) || 0
          } else {
            //
          }

          // Verifica se não é massa/borda normal ou "sem borda" ou "sem massa", para que possa cobrar o valor do complemento
          if (
            `${additional.nm_additional}`.toLowerCase().indexOf("sem b", 0) === -1 &&
            `${additional.nm_additional}`.toLowerCase().indexOf("sem m", 0) === -1 &&
            `${additional.nm_additional}`.toLowerCase().indexOf("normal", 0) === -1
          ) {
            const complementSize = !!complement.sizes ? complement.sizes : !!complement.size ? complement.size : null

            if (!!complementSize) {
              complementValue = complementValue + (complementSize.complement_size?.val_price || 0)
            } else {
              //
            }
          }

          result.push(complementValue)
        }
      }

      return result.length > 0
        ? result.length > 1
          ? result.reduce((sum, number) => {
              return sum + number
            })
          : result[0]
        : 0.0
    }
  }
}

export const getTotalFlavours = (flavours, size, isCombo = false, nro_combo, nro_item, order, numberFlavour) => {
  let result = []

  const flavourQty = !!numberFlavour ? numberFlavour : flavours?.length || 1

  if (isCombo && !!order) {
    if (order.combos.length > 0) {
      const thisCombo = order.combos.find((product) => product.nro_combo === nro_combo)
      const thisProduct = thisCombo.product_items.find((product) => product.nro_item === nro_item)

      let totalFlavourPrices = []
      for (let flavour of flavours) {
        //Caso tenha algum acrescimo por parte de adicionais do sabor
        let flavourPrice = 0

        if (size.st_higher_price) {
          totalFlavourPrices.push(flavour.val_price)
        } else {
          //Verifica se o cálculo dos itens é percentual ou valor fixo.
          if (thisProduct?.st_percentage_calc) {
            const percentageValue = 100 / flavourQty
            flavourPrice = ((flavour.val_price * percentageValue) / 100) * (flavour.amount || 1)
          } else {
            flavourPrice = flavour.val_price * (flavour.amount || 1)
          }
        }

        if (flavour.additions.length > 0) {
          for (let addition of flavour.additions) {
            flavourPrice =
              flavourPrice +
              (!!addition.sizes
                ? addition.sizes.find(({ uuid_size }) => uuid_size === size.uuid_size).ingredient_size.val_price / flavourQty
                : (addition.size.ingredient_size.val_price * addition.amount) / flavourQty)
          }
        }

        result.push(Number(parseFloat(flavourPrice).toFixed(2)))
      }
      if (totalFlavourPrices.length > 0) {
        totalFlavourPrices.sort((a, b) => b - a)
        result.push(totalFlavourPrices[0])
      }
      return result.length > 0
        ? result.length > 1
          ? result.reduce((sum, number) => {
              return sum + number
            }) + (isCombo ? 0 : size.val_price)
          : //TODO: Tratar valor excedente de tamanho
            result[0]
        : 0.0
    }
  } else {
    if (!!size) {
      let totalFlavourPrices = []
      for (let flavour of flavours) {
        //Caso tenha algum acrescimo por parte de adicionais do sabor
        let flavourPrice = 0

        const flavourSize = !!flavour.sizes ? flavour.sizes.find(({ id_size }) => id_size === size.id_size) : !!flavour.size ? flavour.size : null

        //Verifica se o cálculo dos itens é percentual ou valor fixo.
        if (size.st_higher_price) {
          totalFlavourPrices.push(flavourSize?.val_price)
        } else {
          if (size.st_percentage_calc) {
            flavourPrice = (flavourSize?.val_price * (100 / flavourQty)) / 100
            // result.push(parseFloat(flavourPrice * (flavour.amount || 1)).toFixed(2))
          } else {
            flavourPrice = flavourSize?.val_price
            // result.push(parseFloat(flavourPrice * (flavour.amount || 1)).toFixed(2))
          }
        }
        for (let addition of flavour.additions) {
          // console.log("addition", addition)
          const additionSize = !!addition.sizes
            ? addition.sizes.find(({ id_size }) => id_size === size.id_size)
            : !!addition.size
            ? addition.size
            : null
          if (!!additionSize) {
            if (size.st_percentage_calc) {
              const additionalPrice = (additionSize.ingredient_size?.val_price || 0) * addition.amount
              const newFlavorPrice = flavourPrice + additionalPrice / flavourQty
              flavourPrice = newFlavorPrice
              // result.push(parseFloat(flavourPrice * (flavour.amount || 1)).toFixed(2))
            } else {
              flavourPrice = flavourPrice + ((additionSize.ingredient_size?.val_price || 0) * addition.amount) / flavourQty
              // result.push(parseFloat(flavourPrice * (flavour.amount || 1)).toFixed(2))
            }
          }
        }
        // console.log("flavourPrice final", flavourPrice)
        const priceToPush = Number(parseFloat(flavourPrice * (flavour.amount || 1)).toFixed(2))
        // console.log("priceToPush", priceToPush)
        result.push(priceToPush)
        // console.log("result em cada etapa", result)
        // result.push(Number(parseFloat(flavourPrice * (flavour.amount || 1)).toFixed(2)))
      }
      // console.log("totalFlavourPrices", totalFlavourPrices)
      if (totalFlavourPrices.length > 0) {
        totalFlavourPrices.sort((a, b) => b - a)
        result.push(totalFlavourPrices[0])
      }
      result.push(size.val_price)
      return result.length > 0
        ? result.length > 1
          ? result.reduce((sum, number) => {
              return sum + number
            })
          : result[0]
        : 0.0
    }
  }
}

export const getTotalBeverage = (beverage) => {
  const { id_combo = null, nro_product = null, amount = 1, val_price = 0, exceeded_price = null } = beverage

  if (!!id_combo) {
    if (exceeded_price !== null) {
      if (exceeded_price > 0) {
        return `+ ${GeneralTools.formatMoney(GeneralTools.parseFloat(exceeded_price) * amount)}`
      }
      return "Sem exd."
    }
  }

  if (!!nro_product) {
    if (!!beverage.beverage_size) {
      return beverage.beverage_size.val_price > 0 ? GeneralTools.formatMoney(GeneralTools.parseFloat(beverage.beverage_size.val_price)) : "Grátis"
    } else {
      return beverage.val_price > 0 ? GeneralTools.formatMoney(GeneralTools.parseFloat(beverage.val_price)) : "Grátis"
    }
  }

  return !!val_price && val_price > 0 ? GeneralTools.formatMoney(GeneralTools.parseFloat(val_price) * amount) : "Grátis"
}

// Método para extrair dados de cada item do pedido
export const getItemsOrder = (items = []) => {
  let combos = []
  let products = []
  let beverages = []

  let lastIndexProduct = null

  for (let item of items.sort((a, b) => a.id_order_item - b.id_order_item)) {
    const indexProduct = products.findIndex((product) => product.id_order_item === item.id_parent_order_item)

    switch (item.id_class_item) {
      case 1: // Combo
        combos.push({
          id_order_item: item.id_order_item,
          id_combo: item.id_order_class_item,
          uuid_combo: item.uuid_order_class_item,
          nm_combo: item.lb_order_item,
          ds_combo: item.ds_order_item,
          amount: item.amount || 1,
          val_price: formatFloat(item.val_price),
          id_parent_order_item: item.id_parent_order_item,
        })
        break
      case 2: // Product
        // Consultando identificador do combo pelo seu idetificador de item do pedido
        const comboProductFound = combos.find(({ id_order_item }) => id_order_item === item.id_parent_order_item)

        products.push({
          id_combo: comboProductFound ? comboProductFound.id_combo : null,
          id_order_item: item.id_order_item,
          id_parent_order_item: item.id_parent_order_item,
          nro_parent_order_item: item.nro_parent_order_item,
          id_product: item.id_order_class_item,
          uuid_product: item.uuid_order_class_item,
          nm_product: item.lb_order_item,
          ds_product: item.ds_order_item,
          st_completed: item.st_completed,
          amount: item.amount || 1,
          size: {},
          flavours: [],
          additionals: [],
        })
        break
      case 3: // Size
        if (indexProduct !== -1) {
          products[indexProduct] = {
            ...products[indexProduct],
            size: {
              id_size: item.id_order_class_item,
              uuid_size: item.uuid_order_class_item,
              nm_size: item.lb_order_item,
              lb_size: item.ds_order_item,
              val_price: formatFloat(item.val_price),
              show: !!item.lb_order_item && !["único", "unico", "padrão", "padrao", "normal"].includes(item.lb_order_item.toLowerCase()),
            },
          }
        }
        break
      case 4: // Flavour
        if (indexProduct !== -1) {
          for (let i = 0; i < item.amount; i++) {
            const flavour = {
              id_order_item: item.id_order_item,
              id_flavour: item.id_order_class_item,
              uuid_flavour: item.uuid_order_class_item,
              nm_flavour: item.lb_order_item,
              ds_flavour: item.ds_order_item,
              amount: 1,
              val_price: formatFloat(item.val_price),
              restrictions: [],
              additions: [],
            }

            if (!!products[indexProduct].flavours) {
              products[indexProduct].flavours = [...products[indexProduct].flavours, flavour]
            } else {
              products[indexProduct].flavours = [flavour]
            }
          }

          lastIndexProduct = indexProduct
        }
        break
      case 5: // Restriction
        if (lastIndexProduct !== null) {
          const restriction = {
            id_ingredient: item.id_order_class_item,
            uuid_ingredient: item.uuid_order_class_item,
            nm_ingredient: item.lb_order_item,
            ds_ingredient: item.ds_order_item,
            amount: item.amount || 1,
            val_price: formatFloat(item.val_price),
          }

          const indexFlavour = products[lastIndexProduct].flavours.findIndex((flavour) => flavour.id_order_item === item.id_parent_order_item)

          if (indexFlavour !== -1) {
            if (!!products[lastIndexProduct].flavours[indexFlavour].restrictions) {
              products[lastIndexProduct].flavours[indexFlavour].restrictions = [
                ...products[lastIndexProduct].flavours[indexFlavour].restrictions,
                restriction,
              ]
            } else {
              products[lastIndexProduct].flavours[indexFlavour].restrictions = [restriction]
            }
          }
        }
        break
      case 6: // Optional
        if (lastIndexProduct !== null) {
          const addition = {
            id_ingredient: item.id_order_class_item,
            uuid_ingredient: item.uuid_order_class_item,
            nm_ingredient: item.lb_order_item,
            ds_ingredient: item.ds_order_item,
            amount: item.amount || 1,
            val_price: formatFloat(item.val_price),
          }

          const indexFlavour = products[lastIndexProduct].flavours.findIndex((flavour) => flavour.id_order_item === item.id_parent_order_item)

          if (indexFlavour !== -1) {
            if (!!products[lastIndexProduct].flavours[indexFlavour].additions) {
              products[lastIndexProduct].flavours[indexFlavour].additions = [...products[lastIndexProduct].flavours[indexFlavour].additions, addition]
            } else {
              products[lastIndexProduct].flavours[indexFlavour].additions = [addition]
            }
          }
        }
        break
      case 7: // Additional and Complement
        if (indexProduct !== -1) {
          const additional = {
            id_additional: item.id_order_class_item,
            uuid_additional: item.uuid_order_class_item,
            nm_additional: item.lb_order_item,
            ds_additional: item.ds_order_item,
            amount: item.amount || 1,
            val_price: formatFloat(item.val_price),
          }

          if (!!products[indexProduct].additionals) {
            products[indexProduct].additionals = [...products[indexProduct].additionals, additional]
          } else {
            products[indexProduct].additionals = [additional]
          }
        }
        break
      case 8: // Beverage
        // Consultando identificador do combo pelo seu idetificador de item do pedido
        const comboBeverageFound = combos.find(({ id_order_item }) => id_order_item === item.id_parent_order_item)
        // Consultando identificador do produto pelo seu idetificador de item do pedido
        const productBeverageFound = products.find(({ id_order_item }) => id_order_item === item.id_parent_order_item)

        beverages.push({
          id_combo: comboBeverageFound ? comboBeverageFound.id_combo : null,
          id_product: productBeverageFound ? productBeverageFound.id_product : null,
          id_order_item: item.id_order_item,
          id_parent_order_item: item.id_parent_order_item,
          nro_parent_order_item: item.nro_parent_order_item,
          id_beverage: item.id_order_class_item,
          uuid_beverage: item.uuid_order_class_item,
          nm_beverage: item.lb_order_item,
          nm_category: item.ds_order_item || "Bebida",
          amount: item.amount || 1,
          val_price: formatFloat(item.val_price),
        })
        break
      default:
        break
    }
  }

  for (const combo of combos) {
    const productItemsFiltered = products.filter(({ id_parent_order_item }) => id_parent_order_item === combo.id_order_item)
    const beverageItemsFiltered = beverages.filter(({ id_parent_order_item }) => id_parent_order_item === combo.id_order_item)

    for (let i = 1; i < combo.amount; i++) {
      if (productItemsFiltered.length > 0) {
        products = [...products, ...productItemsFiltered]
      }

      if (beverageItemsFiltered.length > 0) {
        beverages = [...beverages, ...beverageItemsFiltered]
      }
    }
  }

  return { combos, products, beverages }
}

// Função para remover um produto do order
export const deleteProductFromOrder = (product, order) => {
  if (!!order) {
    const indexProduct = order.products.findIndex(
      ({ id_product, nro_product }) => id_product === product.id_product && nro_product === product.nro_product
    )

    if (indexProduct !== -1) {
      order.products.splice(indexProduct, 1)

      const indexProductBeverage = order.beverages.findIndex((beverage) => beverage.nro_product === product.nro_product)
      if (indexProductBeverage !== -1) {
        order.beverages.splice(indexProductBeverage, 1)
      }
      return { ...order }
    } else {
      return { ...order }
    }
  } else {
    return { ...order }
  }
}

// Função para alterar a quantidade de produtos no carrinho
export const changeAmountProduct = (amount, indexProduct, order) => {
  let { products, beverages } = order
  if (amount > 0) {
    const productIndex = products.findIndex((product, index) => index === indexProduct)
    if (productIndex !== -1) {
      order.products[productIndex].amount = amount

      const beverageIndex = beverages.findIndex((beverage) => beverage.nro_product === products[productIndex].nro_product)
      if (beverageIndex !== -1) {
        order.beverages[beverageIndex].amount = amount
      }
    }
    return { ...order }
  } else if (amount === 0) {
    const productNumber = products[indexProduct].nro_product

    const beverageIndex = beverages.findIndex((beverage) => beverage.nro_porduct === productNumber)
    order.beverages?.splice(beverageIndex, 1)
    order.products?.splice(indexProduct, 1)

    return { ...order }
  }
}

// Função para altera a quantidade de bebidas
export const changeAmountBeverage = (amount, beverageParam, order) => {
  let beverageIndex = -1
  if (!!beverageParam.nro_product) {
    beverageIndex = order.beverages.findIndex((beverage) => beverage.uuid_beverage === beverageParam.uuid_beverage && !!beverage.nro_product)
  } else if (!!beverageParam.nro_combo) {
    beverageIndex = order.beverages.findIndex((beverage) => beverage.uuid_beverage === beverageParam.uuid_beverage && !!beverage.nro_combo)
  } else {
    beverageIndex = order.beverages.findIndex(
      (beverage) => beverage.uuid_beverage === beverageParam.uuid_beverage && !beverage.nro_product && !beverage.nro_combo
    )
  }
  if (amount > 0) {
    if (beverageIndex !== -1) {
      order.beverages[beverageIndex].amount = amount
      return { ...order }
    }
  } else if (amount === 0) {
    if (beverageIndex !== -1) {
      order.beverages?.splice(beverageIndex, 1)
    }

    return { ...order }
  }
}

// Função para remover uma bebida do order
export const deleteBeverage = (beverage, order) => {
  const index = order.beverages.findIndex(
    ({ id_beverage, id_product, nro_product = null, nro_item = null }) =>
      !id_product && id_beverage === beverage.id_beverage && nro_product === null && nro_item === null
  )

  if (index !== -1) {
    order.beverages.splice(index, 1)

    return { ...order }
  }
}

// Função para remover um combo do order
export const deleteCombo = (combo, order) => {
  const comboIndex = order.combos.findIndex(({ id_combo, nro_combo }) => id_combo === combo.id_combo && nro_combo === combo.nro_combo)

  if (comboIndex !== -1) {
    order.combos.splice(comboIndex, 1)

    const productsFiltered = order.products.filter((product) => (!!product.nro_combo ? product.nro_combo !== combo.nro_combo : true))
    const beveragesFiltered = order.beverages.filter((beverage) => (!!beverage.nro_combo ? beverage.nro_combo !== combo.nro_combo : true))

    return { ...order, products: productsFiltered, beverages: beveragesFiltered }
  } else {
    Message.info("Combo não encontrado")
  }
}

//Cálculo do subtotal do pedido
export const getSubTotalOrder = (order) => {
  const { val_total, pc_discount = null, val_discount = null, val_delivery_tax = 0, val_additional_tax = 0, order_benefits = [] } = order

  let result = parseFloat(val_total) + parseFloat(val_delivery_tax) + parseFloat(val_additional_tax)

  if (order_benefits.length > 0) {
    order_benefits.forEach(({ val_benefit }) => (result = result - val_benefit))
  }

  if (pc_discount) {
    result = pc_discount > 0 ? result - result * (Math.abs(pc_discount) / 100) : result
  } else {
    result = val_discount && val_discount > 0 ? result - parseFloat(val_discount) : result
  }

  return GeneralTools.formatMoney(parseFloat(result))
}

export const getDiscountOrder = (order, subTotal, deliveryFee, settings = {}, modality, review = false) => {
  // let orderSubtotal = subTotal
  let orderSubtotal = !!deliveryFee ? order.total + deliveryFee : order.total
  let totalDiscount = 0
  if (review) {
    orderSubtotal = !!deliveryFee ? subTotal + deliveryFee : subTotal
    if (order.discountType === "R$") {
      totalDiscount = order.discount
    } else if (order.discountType === "%") {
      totalDiscount = orderSubtotal * (Math.abs(order.discount) / 100)
    }
  } else {
    if (!!settings) {
      const { delivery_discount_type = "R$", delivery_discount_value = 0, pickup_discount_type = "R$", pickup_discount_value = 0 } = settings
      if (modality === 1) {
        if (delivery_discount_type === "R$") {
          totalDiscount = delivery_discount_value
        } else {
          totalDiscount = orderSubtotal * (Math.abs(delivery_discount_value) / 100)
        }
      } else {
        if (pickup_discount_type === "R$") {
          totalDiscount = pickup_discount_value
        } else {
          // console.log("1", Math.abs(pickup_discount_value) / 100)
          // console.log("2", orderSubtotal)
          // console.log("3", orderSubtotal * (Math.abs(pickup_discount_value) / 100))
          totalDiscount = orderSubtotal * (Math.abs(pickup_discount_value) / 100)
        }
      }
    }
  }
  if (!!order.fidelity) {
    const { prize_discount = 0, prize_discount_type = false } = order.fidelity
    let fidelityDiscount = 0
    if (prize_discount > 0) {
      if (prize_discount_type) {
        fidelityDiscount = orderSubtotal * (Math.abs(prize_discount) / 100)
      } else {
        fidelityDiscount = prize_discount
      }
    }

    totalDiscount += fidelityDiscount
  }
  // console.log("totalDiscount", totalDiscount)
  return parseFloat(totalDiscount)
}

export const getStepOrder = (order) => {
  if (!!order.dt_deleted_at) {
    return { label: "Cancelado", color: "#D00505", finish: true }
  } else if (!!order.schedule) {
    let finish = true
    let color = "#00BF08"
    let label = "Agendado"

    return { label, color, finish }
  } else {
    const orderProgress = order.order_progress.sort((a, b) => sortList(a, b, "step", "desc"))[0]

    let finish = false
    let color = "#00BF08"
    let label = orderProgress.short_progress

    if ([2, 3, 4].includes(order?.modality.id_modality)) {
      if (orderProgress?.master_step_id === 3) {
        if (orderProgress?.is_final_step) {
          finish = true
          color = "#00BF08"
          label = "Retirado"
        } else {
          color = "#00BF08"
          label = "Pronto para Retirada"
        }
      } else if (!order.st_approved && orderProgress?.master_step_id === 1) {
        label = "Aguardando Aprovação"
        color = "#fcb82c"
      } else if (order.st_approved) {
        label = "Em preparo"
        color = "#fcb82c"
      }
    } else {
      if (orderProgress?.is_final_step) {
        finish = true
        color = "#00BF08"
        label = "Entregue"
      } else if (orderProgress?.master_step_id === 4) {
        color = "#fcb82c"
      } else if (orderProgress?.master_step_id === 2) {
        color = "#fcb82c"
      } else if (!order.st_approved && orderProgress?.master_step_id === 1) {
        label = "Aguardando Aprovação"
        color = "#fcb82c"
      } else if (order.st_approved) {
        label = "Em preparo"
        color = "#fcb82c"
      }
    }

    if (!finish && order.st_confirmed) {
      if ([2, 3, 4].includes(order?.modality.id_modality)) {
        finish = true
        color = "#00BF08"
        label = "Retirado"
      } else {
        finish = true
        color = "#00BF08"
        label = "Entregue"
      }
    }

    return { label, color, finish }
  }
}

export const parseWebsiteOrder = (order, customer) => {
  const [total] = getTotalOrder(order)

  let params = {
    id_modality: order.id_modality,
    uuid_customer: customer.uuid_customer,
    id_street_customer: customer.id_street_customer,
    id_customer_address: customer.id_customer_address,
    ds_origin: "site",
    ds_obs: null,
    ds_delivery_obs: order.deliveryComments,
    ds_discount_type: order.discountType,
    val_total: total,
    val_discount: order.discountType === "R$" ? GeneralTools.parseFloat(order.discount || 0) : null,
    pc_discount: order.discountType === "%" ? GeneralTools.parseFloat(order.discount || 0) : null,
    ds_discount: order.ds_discount,
    val_benefits: 0,
    val_delivery_tax: order.id_modality === 1 ? GeneralTools.parseFloat(order.deliveryFee) : 0,
    val_additional_tax: 0,
    delivery_time: order.deliveryTime,
    st_approved: false,
    st_scheduled: order.scheduled,
    st_resended: order.st_resended,
    dt_schedule_start: order.scheduleTime,
    dt_schedule_end: order.scheduleTime,
  }
  let fidelity = order.discountFidelityId || null

  if (order.benefits.length > 0) {
    params.benefits = order.benefits.map((benefit) => {
      if (!fidelity && !!benefit.id_fidelity) fidelity = benefit.id_fidelity
      const valBenefit = validateBenefitsValue(order, benefit)
      params.val_benefits = params.val_benefits + valBenefit
      return {
        ...benefit,
        val_benefit: valBenefit,
        nm_sponsorship: "site",
      }
    })
  } else {
    params.val_benefits = 0
  }

  let newCombos = [...order.combos]
  let newOrderProducts = [...order.products]
  let newOrderBeverages = [...order.beverages]

  params.combos = newCombos.map(({ id_combo, uuid_combo, nro_combo, nm_combo, amount, val_price, ds_obs }) => {
    return {
      id_combo,
      uuid_combo,
      nro_combo,
      nm_combo,
      amount,
      ds_obs,
      val_price: GeneralTools.parseFloat(val_price),
    }
  })

  params.products = newOrderProducts.map((product) => {
    // console.log("--------------------------------")
    // console.log("product", product)
    //Função para organizar os complementos do produto
    const getComplementsItems = (complements) => {
      const getPriceComplement = (complement, additional) => {
        // console.log("############")
        // console.log("getPriceComplement", { complement, additional })
        const combo = newCombos.find(({ nro_combo }) => nro_combo === product.nro_combo)
        if (!!combo) {
          const addPrice = additional.val_price
          const complPrice = complement.val_price

          return complPrice + addPrice
        } else {
          let complementValue = 0
          if (!!additional.sizes) {
            complementValue = additional.sizes.find(({ id_size }) => id_size === product.size.id_size).additional_size.val_price
          } else if (!!additional.size) {
            complementValue = additional.size.additional_size.val_price
          }

          if (`${additional.nm_additional}`.toLowerCase().indexOf("sem", 0) === -1) {
            if (!!complement.sizes) {
              complementValue = complementValue + complement.sizes.find(({ id_size }) => id_size === product.size.id_size).complement_size.val_price
            } else if (!!complement.size) {
              complementValue = complementValue + complement.size.complement_size.val_price
            }
          }
          // console.log("complementValue", complementValue)
          return GeneralTools.parseFloat(complementValue)
        }
      }

      complements.forEach((complement) => {
        if (!!complement.complements) complement.additionals = complement.complements
        complement.additionals.forEach((additional) => {
          additionals.push({
            id_complement: complement.id_complement,
            id_additional: additional.id_additional,
            uuid_complement: complement.uuid_complement,
            uuid_additional: additional.uuid_additional,
            nm_complement: complement.nm_complement,
            nm_additional: getAdditionalName(additional, complement),
            amount: complement.amount || 1,
            val_price: getPriceComplement(complement, additional),
          })
        })
      })
    }

    const getTotalProduct = (product) => {
      if (!!product.total) return product.total
      return 0
    }

    const newProduct = { ...product }

    const flavours = product.flavours.map(
      ({
        uuid_flavour,
        id_flavour,
        nm_flavour,
        ds_flavour = null,
        ds_obs = null,
        size = null,
        sizes = [],
        restrictions = [],
        additions = [],
        lb_flavour_prefix = null,
        st_emphasize = false,
        amount,
      }) => {
        const val_price = getPriceFlavour(!!newProduct.id_combo, newProduct, id_flavour, size)
        return {
          id_flavour,
          uuid_flavour,
          nm_flavour,
          ds_flavour,
          ds_obs,
          amount,
          val_price,
          lb_flavour_prefix,
          st_emphasize,
          restrictions: restrictions.map(({ uuid_ingredient, id_ingredient, nm_ingredient }) => {
            return { uuid_ingredient, id_ingredient, nm_ingredient }
          }),
          optionals: additions.map(({ id_ingredient, uuid_ingredient, nm_ingredient, amount }, index) => {
            return {
              id_ingredient,
              uuid_ingredient,
              nm_ingredient,
              amount,
              val_price: getPriceAddition(order, product, additions[index], id_ingredient, nm_ingredient, amount, product.size, sizes),
              // val_price: !!size
              //   ? (size.ingredient_size.val_price / product.flavours.length) * amount
              //   : (sizes.find(({ uuid_size }) => uuid_size === product.size.uuid_size).val_price / product.flavours.length) * amount,
            }
          }),
        }
      }
    )

    //Adicionais são os complementos do produto
    let additionals = []
    if (!!product.complements) {
      getComplementsItems(product.complements)
    }

    if (!fidelity && !!product.id_fidelity) fidelity = product.id_fidelity
    return {
      id_combo: product.id_combo || null,
      nro_combo: product.nro_combo || null,
      id_product: product.fk_id_product || product.id_product,
      nro_product: product.nro_product || null,
      uuid_product: product.uuid_product,
      nm_product: product.nm_product,
      amount: product.amount,
      val_price: getTotalProduct(product),
      ds_obs: product.ds_obs,
      dough: {
        id_dough: 1,
        nm_dough: "Normal",
      },
      size: {
        id_size: product.size.id_size,
        uuid_size: product.size.uuid_size,
        nm_size: product.size.nm_size,
        val_price: product.size.val_price,
        val_delivery_payment: product.size.val_delivery_payment,
      },
      flavours,
      additionals,
    }
  })

  params.beverages = newOrderBeverages.map(
    ({
      id_combo = null,
      nro_combo = null,
      id_product = null,
      nro_product = null,
      id_fidelity = null,
      uuid_beverage,
      id_beverage,
      nm_beverage,
      amount,
      val_price = 0,
    }) => {
      if (!fidelity && !!id_fidelity) fidelity = id_fidelity

      return {
        id_combo,
        nro_combo,
        id_product,
        nro_product,
        uuid_beverage,
        id_beverage,
        nm_beverage,
        amount,
        val_price: GeneralTools.parseFloat(val_price),
      }
    }
  )
  //A rotina de inserir o cupom já ajusta o valor do subtotal do order para que seja exibido no restante do ste o valor atualizado.
  // Removi a parte no qual ele removia o valor do cupom do total do pagamento, desta forma não será mais excluido
  //  em duplicidade o valor do cupom na hora do troco.
  params.payments = order.payments.map(({ id_payment_method, nm_payment_method, val_receivable, val_payment }) => {
    return {
      id_payment_method,
      nm_payment: nm_payment_method,
      val_receivable: GeneralTools.parseFloat(val_receivable),
      val_payment: GeneralTools.parseFloat(val_payment),
    }
  })
  if (fidelity) params.id_fidelity = fidelity

  //ALterando o order_json após o spread
  params = {
    ...params,
    order_json: {
      ...order,
      newOrderBeverages,
      newOrderProducts,
      newCombos,
      beverages: [...params.beverages],
      products: [...params.products],
      combos: [...params.combos],
      customer,
      device: {
        version: process.env.REACT_APP_VERSION,
        witdh: window.innerWidth,
        height: window.innerHeight,
        userAgent: window.navigator.userAgent,
      },
    },
  }

  return params
}
