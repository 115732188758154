import React from "react"
import { Card, CardBody, CardImg, CardText, CardTitle, Label } from "reactstrap"

// Styles
import "./Products.scss"
// Resources
import { formatMoney, getUrlAWSManagerFile, setFirstLetter } from "../../helpers/GeneralTools"

// Imagens
import noImage from "../../assets/img/builder/no-image.png"

// Contexts
import SettingsContext from "../../contexts/SettingsContext"

const Products = ({ source, onClickProduct }) => {
  const { products } = React.useContext(SettingsContext)
  // console.log("products", products)

  const getIngredients = (ingredients) => {
    return !!ingredients && ingredients.length > 0 ? ingredients.map((item) => item.nm_ingredient).join(", ") : "Sem descrição"
  }

  const getLowerFlavourPrice = (sizes) => {
    // console.log("sizes", sizes)
    // const prices = sizes.map(({ flavour_size: { val_price } }) => parseFloat(val_price))
    // console.log("prices", prices)
    // return formatMoney(prices.length > 0 ? Math.min.apply(Math, prices) : 0)
    return 123
  }

  const getFlavourPrice = (flavour, sizes = []) => {
    if (sizes.length >= 0) {
      try {
        return formatMoney(flavour.sizes[0].flavour_size.val_price)
      } catch (error) {
        return ""
      }
    }
  }

  return (
    <section id="products" className="section-products">
      <div className={(source === "builder" ? "pl-30 pr-30" : "") + " content-products"}>
        {products
          .filter((product) => {
            const { st_display_mode, flavours, sizes } = product
            if (st_display_mode === "size") {
              return true
            } else if (st_display_mode === "flavour") {
              return flavours.length > 0 && sizes.length > 0
            }
            return false
          })
          .map(({ uuid_product, nm_product, ds_product, st_display_mode, lb_product, sizes, flavours }, key) => (
            <div id={uuid_product} className="product" key={key}>
              <div className="product-title">
                <label>{setFirstLetter(!!lb_product ? lb_product : nm_product)}</label>
                <span>{setFirstLetter(ds_product || "")}</span>
              </div>
              <div className="product-content">
                {(() => {
                  if (st_display_mode === "size") {
                    return sizes.map((size, key) => {
                      return (
                        <Card
                          key={key}
                          className="shadow-05 card-product"
                          onClick={() =>
                            onClickProduct("size", {
                              uuid_product,
                              nm_product,
                              ds_product,
                              size,
                            })
                          }
                        >
                          <CardBody>
                            <strong className="card-title">{!!size.lb_size ? size.lb_size : size.nm_size}</strong>
                            <CardText className="card-description">{size.ds_size || "Sem descrição"}</CardText>
                            <div>
                              {!!size.lb_val_price_back || !!size.val_price_back ? (
                                <div className="card-price--back">
                                  {!!size.lb_val_price_back && <span>{size.lb_val_price_back}&nbsp;</span>}
                                  {!!size.val_price_back && <span>{formatMoney(!!size.val_price_back ? size.val_price_back : 0)}</span>}
                                </div>
                              ) : (
                                ""
                              )}
                              {!!size.lb_val_price_sample || !!size.val_price_sample ? (
                                <>
                                  {!!size.lb_val_price_sample && <span className="card-price">{size.lb_val_price_sample}</span>}
                                  {!!size.val_price_sample ? <span className="card-price">{formatMoney(size.val_price_sample)}</span> : ""}
                                </>
                              ) : (
                                ""
                              )}

                              {!size.lb_val_price_back &&
                              !size.val_price_back &&
                              !size.val_price_sample &&
                              !size.lb_val_price_sample &&
                              !!size.val_price &&
                              size.val_price > 0 ? (
                                <span className="card-price">{formatMoney(size.val_price)}</span>
                              ) : (
                                ""
                              )}
                            </div>
                          </CardBody>
                          <div className="card-preview-image">
                            {(() => {
                              if (!!size.lb_tag_image) {
                                return (
                                  <div className="flag-image">
                                    <div
                                      className="flag-image--body"
                                      style={{
                                        backgroundColor: size.color_tag_main_image || "#F44336",
                                        borderRightColor: size.color_tag_main_image || "#F44336",
                                      }}
                                    >
                                      <div>
                                        <Label>{size.lb_tag_image}</Label>
                                      </div>
                                    </div>
                                  </div>
                                )
                              }
                            })()}
                            <CardImg alt="" width="100%" src={getUrlAWSManagerFile(size.url_image, noImage)} />
                          </div>
                        </Card>
                      )
                    })
                  } else {
                    return flavours.map((flavour, key) => (
                      <Card
                        key={key}
                        className="shadow-05 card-product"
                        onClick={() =>
                          onClickProduct("flavour", {
                            uuid_product,
                            flavour: {
                              ...flavour,
                              sizes,
                            },
                            productIndex: key,
                          })
                        }
                      >
                        {/*{console.log("flavour", { flavour, sizes })}*/}
                        <CardBody>
                          <CardTitle>{!!flavour.lb_flavour ? flavour.lb_flavour : flavour.nm_flavour}</CardTitle>
                          <CardText className="card-description">{flavour.ds_flavour || getIngredients(flavour.ingredients)}</CardText>
                          <div>
                            {!!flavour.lb_val_price_back || !!flavour.val_price_back ? (
                              <div className="card-price--back">
                                {!!flavour.lb_val_price_back && <span>{flavour.lb_val_price_back}&nbsp;</span>}
                                {!!flavour.val_price_back && <span>{formatMoney(flavour.val_price_back)}</span>}
                              </div>
                            ) : (
                              ""
                            )}
                            {!!flavour.lb_val_price_sample || !!flavour.val_price_sample ? (
                              <>
                                {!!flavour.lb_val_price_sample && <span className="card-price">{flavour.lb_val_price_sample}</span>}
                                {!!flavour.val_price_sample && <span className="card-price">{formatMoney(flavour.val_price_sample)}</span>}
                              </>
                            ) : (
                              <span className="card-price">{getFlavourPrice(flavour, sizes)}</span>
                            )}
                          </div>
                        </CardBody>
                        <div className="card-preview-image">
                          {(() => {
                            if (flavour.lb_tag_main_image) {
                              return (
                                <div className="flag-image">
                                  <div
                                    className="flag-image--body"
                                    style={{
                                      backgroundColor: flavour.color_tag_main_image || "#F44336",
                                      borderRightColor: flavour.color_tag_main_image || "#F44336",
                                    }}
                                  >
                                    <div>
                                      <Label>{flavour.lb_tag_main_image}</Label>
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          })()}
                          <CardImg alt="" width="100%" src={getUrlAWSManagerFile(flavour.url_main_image, noImage)} />
                        </div>
                      </Card>
                    ))
                  }
                })()}
              </div>
            </div>
          ))}
      </div>
    </section>
  )
}

export default Products
