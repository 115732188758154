import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { formatMoney } from "../../helpers/GeneralTools"
import QuantityControl from "../QuantityControl/QuantityControl"

import noImage from "../../assets/img/builder/no-image.png"
import "./ProductItem.scss"

const ProductItem = ({ isCombo = false, productData = {}, changeAmount, promotionalItem = false }) => {
  return (
    <div className="product-item">
      <div className="product-item--data">
        <div className="product-item--data--left">
          {/*<img alt="" className="product-item--data--left--image" src={noImage} />*/}
          <img alt="" className="product-item--data--left--image" src={productData.image || noImage} />
          {/* {!isCombo ? (
            <div className="product-item--data--left--edit-button">
              <FontAwesomeIcon icon={"pen"} />
            </div>
          ) : (
            ""
          )} */}
        </div>
        <div className="product-item--data--center">
          <span className="product-item--data--center--title">{productData.title}</span>
          {!!productData.flavours && productData.flavours.length > 0
            ? productData.flavours.map((flavour, index) => {
                return (
                  <div key={index} className="product-item--data--center--flavour">
                    <span>
                      {flavour.flavour_name}
                      {flavour.val_price > 0 ? (
                        <span className="product-item--data--center--flavour--value">{` ${formatMoney(flavour.val_price)}`}</span>
                      ) : (
                        ""
                      )}
                    </span>

                    {!!flavour.additions && flavour.additions.length > 0
                      ? flavour.additions.map((addition, index) => {
                          return (
                            <span key={index} className="product-item--data--center--flavour--additional">
                              + {addition.nm_ingredient}
                              {addition.val_price > 0 ? (
                                <span className="product-item--data--center--flavour--additional--value">{` ${formatMoney(
                                  addition.val_price
                                )}`}</span>
                              ) : (
                                ""
                              )}
                            </span>
                          )
                        })
                      : ""}

                    {!!flavour.restrictions && flavour.restrictions.length > 0
                      ? flavour.restrictions.map((restriction, index) => {
                          return <span key={index} className="product-item--data--center--flavour--ingredient">{`- ${restriction}`}</span>
                        })
                      : ""}
                  </div>
                )
              })
            : ""}

          {!!productData.complements && productData.complements.length > 0
            ? productData.complements.map((complement, index) => {
                return (
                  <div key={index} className="product-item--data--center--complement">
                    <span>
                      {complement.nm_complement}
                      {complement.val_price > 0 ? (
                        <span className="product-item--data--center--complement--value">{` ${formatMoney(complement.val_price)}`}</span>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                )
              })
            : ""}

          {!!productData.observation ? <span className="product-item--data--center--product-observation">{productData.observation}</span> : ""}
        </div>
        <div className="product-item--data--right">
          <span>{productData.val_price}</span>

          {!isCombo && !promotionalItem ? (
            <QuantityControl
              onChange={(amount) => changeAmount(productData.id_product, productData.nro_product, productData.nro_combo, amount)}
              amount={productData.amount}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      {!isCombo ? <div className="product-item--divisor" /> : ""}
    </div>
  )
}

export default ProductItem
