import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import "./CFButton.scss"

const CFButton = ({ title, onClick, outline = false, icon = null, style = {}, disabled = false, extraClass = "" }) => {
  return (
    <button
      //
      onClick={onClick}
      className={!disabled ? "component-button " + (outline ? " button-props-outline " : "") + extraClass : "component-button button-props-disabled"}
      style={style}
      disabled={disabled}
    >
      {icon ? <FontAwesomeIcon icon={icon} /> : ""}
      <p>{title}</p>
    </button>
  )
}

export default CFButton
