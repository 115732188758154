import React from "react"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

//Contexts
import MenuContext from "../../../contexts/MenuContext"
import OrderContext from "../../../contexts/OrderContext"
import SettingsContext from "../../../contexts/SettingsContext"

//Components
import CFButton from "../../../components/CFButton/CFButton"
import PaymentInfo from "../../../components/PaymentInfo/PaymentInfo"
import DeliveryInfo from "../../../components/DeliveryInfo/DeliveryInfo"
import OrderTotalInfo from "../../../components/OrderTotalInfo/OrderTotalInfo"
import PaymentTypeSelector from "../../../components/PaymentInfo/PaymentTypeSelector"

//Helpers
import { getDiscountOrder, getTotalOrder } from "../../../helpers/OrderTools"
import { getCashbackValue, getLocalUser } from "../../../helpers/UserTools"
import { formatBoolean, formatFloat, formatMoney, onMessageDialog } from "../../../helpers/GeneralTools"

import "./ModalOrderFinisher.scss"
import UserContext from "../../../contexts/UserContext"
import CFCheckButton from "../../../components/CFCheckButton/CFCheckButton"
import CompanyContext from "../../../contexts/CompanyContext"
import PaymentsController from "../../../controllers/PaymentsController"

const ModalOrderFinisher = ({ status, onClose, finishOrder }) => {
  const { setModalSignIn, setModalInitialAddress, showPaymentTypeSelector, setShowPaymentTypeSelector } = React.useContext(MenuContext)
  const { order, setOrder, selectedPaymentMethod, setSelectedPaymentMethod, preFinishOrder, scheduleInfo } = React.useContext(OrderContext)
  const { settings, modality } = React.useContext(SettingsContext)
  const { company } = React.useContext(CompanyContext)
  const { userSelectedAddress, user } = React.useContext(UserContext)

  const [paymentOnDelivery, setPaymentOnDelivery] = React.useState(true)
  const [onlinePayment, setOnlinePayment] = React.useState(false)
  const [useCashbackAccountValue, setUseCashbackAccountValue] = React.useState(false)

  React.useEffect(() => {
    if (status) {
      if ((!userSelectedAddress && modality === 1) || modality === 0) {
        setModalInitialAddress({ status: true, title: "", content: { source: "orderFinisher" } })
      } else if (order?.payments.length === 0) {
        setShowPaymentTypeSelector(!showPaymentTypeSelector)
      }
    }
  }, [status, userSelectedAddress, modality])

  const selectPaymentOrder = (paymentChange = 0) => {
    let { payments } = order

    const hasCashback = payments.find(({ nm_payment_method }) => nm_payment_method.toLowerCase() === "cashback")
    const paymentIndex = payments.findIndex(({ nm_payment_method }) => nm_payment_method.toLowerCase() !== "cashback")
    if (paymentIndex !== -1) {
      payments.splice(paymentIndex, 1)
    }

    if (hasCashback) {
      payments = [
        ...order.payments,
        {
          ...selectedPaymentMethod,
          val_receivable: paymentChange,
          val_payment:
            order.subTotal +
            (modality !== 2 ? userSelectedAddress?.deliveryFee || 0 : 0) -
            getDiscountOrder({ ...order }, order.subTotal, modality !== 2 ? userSelectedAddress?.deliveryFee || 0 : 0) -
            (!!order.payments.find(({ nm_payment_method }) => nm_payment_method.toLowerCase() === "cashback")
              ? order.payments.find(({ nm_payment_method }) => nm_payment_method.toLowerCase() === "cashback").val_payment
              : 0),
        },
      ]
    } else {
      payments = [
        {
          ...selectedPaymentMethod,
          val_receivable: paymentChange,
          val_payment:
            order.subTotal +
            (modality !== 2 ? userSelectedAddress?.deliveryFee || 0 : 0) -
            getDiscountOrder({ ...order }, order.subTotal, modality !== 2 ? userSelectedAddress?.deliveryFee || 0 : 0) -
            (!!order.payments.find(({ nm_payment_method }) => nm_payment_method.toLowerCase() === "cashback")
              ? order.payments.find(({ nm_payment_method }) => nm_payment_method.toLowerCase() === "cashback").val_payment
              : 0),
        },
      ]
    }

    setOrder({
      ...order,
      payments,
    })
    setShowPaymentTypeSelector(false)
  }

  const handleUserCashbackValue = (status) => {
    const { company_payment_methods = null } = company
    let { payments = [] } = order
    //Caso tenha marcado o status para usar o cashback
    const { value } = getCashbackValue(user?.movements_account)
    const orderTotal =
      order.subTotal +
      (modality !== 2 ? userSelectedAddress?.deliveryFee || 0 : 0) -
      getDiscountOrder({ ...order }, order.subTotal, modality !== 2 ? userSelectedAddress?.deliveryFee || 0 : 0)

    const accountValueUsed = formatFloat(
      value >= settings.cashback_payment_limit ? settings.cashback_payment_limit : value >= orderTotal ? orderTotal : value
    )

    if (!!status) {
      if (!!company_payment_methods) {
        const cashbackPaymentMethod = company_payment_methods.find(({ nm_payment_method }) => nm_payment_method === "Cashback")
        if (!!cashbackPaymentMethod) {
          //Se o saldo da conta for maior que o valor limite de uso, o valor de uso será o valor limite,
          // caso contrário se o saldo for maior que o valor do pedido, o valor usado será o total do pedido
          //  se não, o valor usado será o total do saldo da conta
          //Verificando se o cliente já selecionou um pagamento antes de escolher usar o cashback
          if (payments.length > 0) {
            if (accountValueUsed >= orderTotal) {
              order.payments = [
                {
                  ...cashbackPaymentMethod,
                  val_receivable: 0,
                  val_payment: accountValueUsed,
                },
              ]
            } else {
              const existingPayment = order.payments[0]
              const existingPaymentDiference = order.payments[0].val_payment - accountValueUsed

              if (existingPaymentDiference <= 0) {
                order.payments = [
                  {
                    ...cashbackPaymentMethod,
                    val_receivable: 0,
                    val_payment: accountValueUsed,
                  },
                ]
              } else {
                order.payments = [
                  {
                    ...existingPayment,
                    val_payment: existingPaymentDiference,
                  },
                  {
                    ...cashbackPaymentMethod,
                    val_receivable: 0,
                    val_payment: accountValueUsed,
                  },
                ]
              }
            }

            //
          } else {
            order.payments = [
              {
                ...cashbackPaymentMethod,
                val_receivable: 0,
                val_payment: accountValueUsed,
              },
            ]
          }
        }
      }
    } else {
      //Em caso de status false, varre os pagamentos, e remove o cashback,
      //Se tiver alguma outra forma de pagamento, aumenta o valor do pagamento para o total do pedido.
      if (payments.length > 1) {
        // const [normalPayment, cashbackPayment] = payments
        // const hasCashback = payments.find(({ nm_payment_method }) => nm_payment_method.toLowerCase() === "cashback")
        const normalPayment = payments.find(({ nm_payment_method }) => nm_payment_method.toLowerCase() !== "cashback")

        if (!!normalPayment && normalPayment.val_receivable < orderTotal && normalPayment.nm_payment_method === "Dinheiro") {
          onMessageDialog("warning").fire({
            title: "O total do pagamento não pode ser menor que o total do pedido, por favor, selecione uma nova forma de pagamento.",
            icon: "warning",
            timer: 4000,
            showCancelButton: false,
            showConfirmButton: false,
          })

          order.payments = []
        } else {
          order.payments = [
            {
              ...normalPayment,
              val_payment:
                order.subTotal +
                (modality !== 2 ? userSelectedAddress?.deliveryFee || 0 : 0) -
                getDiscountOrder({ ...order }, order.subTotal, modality !== 2 ? userSelectedAddress?.deliveryFee || 0 : 0),
            },
          ]
        }
      } else if (payments.length === 1) {
        const cashbackPaymentMethodIndex = order.payments.findIndex(({ nm_payment_method }) => nm_payment_method === "Cashback")
        if (cashbackPaymentMethodIndex !== -1) order.payments = []
      }
    }

    setOrder({ ...order })
    setUseCashbackAccountValue(status)
  }

  const getDeliveryDataTimes = () => {
    if (modality === 2) {
      return `Retirar na loja, ${Number(settings.pickup_time)} min`
    } else if (modality === 1 && !!userSelectedAddress) {
      if (!!userSelectedAddress.deliveryTime) {
        if (!!settings.site_tolerance_time && Number(settings.site_tolerance_time) >= 1) {
          return `Entrega hoje, ${userSelectedAddress.deliveryTime} - ${userSelectedAddress.deliveryTime + Number(settings.site_tolerance_time)} min`
        } else {
          return `Entrega hoje, ${userSelectedAddress.deliveryTime} min`
        }
      } else if (!!settings.delivery_time) {
        if (!!settings.site_tolerance_time && Number(settings.site_tolerance_time) >= 1) {
          return `Entrega hoje, ${settings.delivery_time} - ${Number(settings.delivery_time) + Number(settings.site_tolerance_time)} min`
        } else {
          return `Entrega hoje, ${settings.delivery_time} min`
        }
      }
    }
    return ""
  }

  return (
    <Modal
      isOpen={status}
      toggle={() => {
        onClose()
      }}
      backdrop={"static"}
      fade={false}
      className="modal-order-finisher "
    >
      {showPaymentTypeSelector ? (
        <PaymentTypeSelector
          paymentOnDelivery={paymentOnDelivery}
          setPaymentOnDelivery={setPaymentOnDelivery}
          setOnlinePayment={setOnlinePayment}
          onlinePayment={onlinePayment}
          selectPaymentOrder={selectPaymentOrder}
          onClose={() => setShowPaymentTypeSelector(false)}
          selectedPaymentMethod={selectedPaymentMethod}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
        />
      ) : (
        <>
          <ModalHeader className="modal-order-finisher--content--header">
            <DeliveryInfo
              handleDeliveryTypeSelector={() => {
                setModalInitialAddress({ status: true, title: "", content: { source: "orderFinisher" } })
              }}
            />
            <span className="modal-order-finisher--content--header--delivery-timer">{getDeliveryDataTimes()}</span>
          </ModalHeader>
          <ModalBody className="modal-order-finisher--content--body">
            <span className="modal-order-finisher--content--body--section">VALORES</span>
            {(() => {
              const [total, subTotal, benefit] = getTotalOrder(order)
              const paymentChange = order?.payments[0]?.val_receivable || 0
              const totalPayments = order?.payments.reduce((acc, payment) => {
                return formatFloat(payment.val_payment) + acc
              }, 0)
              const cashbackPayment = order?.payments[1]?.val_payment
              const deliveryFeeValue = modality === 1 ? userSelectedAddress?.deliveryFee || 0 : 0
              const discount = getDiscountOrder(order, subTotal, deliveryFeeValue, settings, modality)
              const discountType = order.discountType
              const discountOrder = order.discount

              return (
                <OrderTotalInfo
                  total={total}
                  totalBenefits={benefit}
                  deliveryFee={deliveryFeeValue}
                  change={paymentChange}
                  additionalFee={0}
                  totalPayment={totalPayments}
                  cashbackPayment={cashbackPayment}
                  totalDiscount={discount}
                  discountTypeOrder={discountType}
                  discountOrder={discountOrder}
                />
              )
            })()}

            {/*Rotina de Cashback*/}
            {(() => {
              const { value } = getCashbackValue(user?.movements_account)
              const [total, subTotal] = getTotalOrder(order)

              if (value > 0 && formatBoolean(settings?.enable_cashback) && formatFloat(settings?.cashback_minimum_value) <= subTotal) {
                return (
                  <>
                    <span className="modal-order-finisher--content--body--section">CASHBACK</span>
                    <div className="modal-order-finisher--content--body--section--cashback">
                      <label>Você possui {formatMoney(value)} de saldo na sua conta de Cashback, deseja utiliza-lo neste pedido?</label>
                      <CFCheckButton status={useCashbackAccountValue} onClick={handleUserCashbackValue} />
                    </div>
                  </>
                )
              }
            })()}

            <span className="modal-order-finisher--content--body--section">PAGAMENTO</span>

            <PaymentInfo
              paymentOnDelivery={paymentOnDelivery}
              handlePaymentTypeSelector={() => setShowPaymentTypeSelector(!showPaymentTypeSelector)}
            />
          </ModalBody>
          <ModalFooter className="modal-order-finisher--content--footer">
            <div className={"modal-order-finisher--content--footer--cancel "}>
              <CFButton title="Voltar" onClick={onClose} outline={true} />
            </div>
            <div className="modal-order-finisher--content--footer--confirm">
              <CFButton
                title="Enviar pedido"
                onClick={() => {
                  const user = getLocalUser()

                  if (!user || (!!user && !user.uuid_customer)) {
                    onMessageDialog("warning")
                      .fire({
                        title: "Necessário realizar login para realizar o pedido!",
                        icon: "warning",
                        timer: 4000,
                        showCancelButton: false,
                        showConfirmButton: false,
                        reverseButtons: true,
                      })
                      .then(() => {
                        setModalSignIn({ status: true })
                      })
                  } else if (!userSelectedAddress && modality !== 2) {
                    onMessageDialog()
                      .fire({
                        title: "Necessário selecionar um endereço!",
                        icon: "warning",
                        timer: 4000,
                        showCancelButton: false,
                        showConfirmButton: false,
                      })
                      .then()
                  } else if (order.payments.length === 0) {
                    onMessageDialog()
                      .fire({
                        title: "Necessário selecionar uma forma de pagamento!",
                        icon: "warning",
                        timer: 4000,
                        showCancelButton: false,
                        showConfirmButton: false,
                      })
                      .then()
                  } else {
                    // const paymentType =
                    //   order.payments[0].id_payment_method === 38 ? "pix" : order.payments[0].id_payment_method === 37 ? "creditcard" : null
                    //
                    // finishOrder("direct", null, false, [37, 38].includes(order.payments[0].id_payment_method), paymentType)
                    preFinishOrder(scheduleInfo)
                  }
                }}
              />
            </div>
          </ModalFooter>
        </>
      )}
    </Modal>
  )
}

export default ModalOrderFinisher
