import React from "react"

//Components
import CFCheckButton from "../CFCheckButton/CFCheckButton"

import "./ScheduleWorkingTime.scss"
import { getFormatDate } from "../../helpers/GeneralTools"

const ScheduleWorkingTime = ({ contentData, onSelectWorkingTime, selected = false }) => {
  return (
    <div className="schedule-working-time">
      <CFCheckButton radio={true} onClick={onSelectWorkingTime} status={selected} />
      {`${getFormatDate(new Date("2023-1-1 " + contentData.hr_start)).getHour()} - ${getFormatDate(
        new Date("2023-1-1 " + contentData.hr_end)
      ).getHour()}`}
    </div>
  )
}

export default ScheduleWorkingTime
